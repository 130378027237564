/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { SharedModule } from '../../shared/shared.moudle';
import { AuthenticationService } from '../../../services/authentication.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { STATUS_ACTIVE } from '../../../data-models/user-login';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [
    SharedModule,
  ],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent implements OnInit {
  resetForm: FormGroup | any;
  show = false;
  submmited = false;
  notRegistered = false
  fetchedToken: any = ""

  constructor(
    private auth: AuthenticationService,
    private router: Router,
    private toastr: ToastrService
  ) { }


  ngOnInit(): void {
    this.resetForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
    })
  }

  onReset() {
    this.submmited = true
    if (this.resetForm.valid)
      this.login(this.resetForm.controls.email.value)
  }

  login(email: any) {
    this.auth.checkEmail(email).subscribe((result) => {
      if (result.active_status === STATUS_ACTIVE) {
        this.auth.generateToken(email).subscribe((res) => {
          this.auth.sendMailToResetPassword(res.token, email).subscribe(() => {
            this.toastr.success("Password reset link is sent to your registered email.", 'Password Reset Link', {
              progressBar: true,
              closeButton: true
            })
            this.router.navigate(['user/send']);
          })
        })
      } else if (result['error'] === "no results found") {
        this.notRegistered = true
        this.toastr.warning("Your email account is not registered.", 'Wrong Email', {
          progressBar: true,
          closeButton: true
        })
        this.notRegistered = true
      } else {
        this.toastr.warning("Your Account has been deactivted, contact Dartle support.", 'Warning', {
          progressBar: true,
          closeButton: true
        })
      }
    },
      error => {
        console.log(error)
      })
  }
}
