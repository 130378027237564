<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/alt-text -->
<div class="row pl-3 pr-3">
  <div class="col-12">
      <div class="row" style="padding-bottom: 7rem;">
          <div class="col-12 profile-container">
              <div class="cover-bg"></div>
              <div class="baisc-info">
                  <img src="{{user.basic_information.dp}}" class="profile-pic-main" (error)="onImgError($event)">
                  <div class="profile-type">
                      <div class="profile-name">{{user.basic_information.name.first_name || ""}}
                          {{user.basic_information.name.last_name || ""}}</div>
                      <div class="type-container">
                          <div class="profile-type-text" *ngFor="let privacy of user.profile_type">
                              <img src="images/icons/ic_profile_orange.png" class="icon"
                                  style="transform: translateY(0%);margin-right: .5rem;">{{privacy}}
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <div class="row mb-3">
          <div class="col-12 mb-3 flex grid-gap mobile">
              <div class="col-lg-6 flex flex-c p-0 grid-gap" style="height: fit-content;">
                  <!-- personal info -->
                  <div class="col-lg-12 p-0"
                      *ngIf="memberAccessControl.view_profile_personal_info.permissions | module: 'member management'">
                      <div class="card p-5">
                          <div style="display:flex; justify-content:space-between;">
                              <div class="header pb-4"> Personal info</div>
                              <div style="cursor: pointer">
                                  <svg (click)="personalEdit();"
                                      *ngIf="(!visible) && (memberAccessControl.edit_profile_personal_info.permissions | module: 'member management')"
                                      width="24" height="25" viewBox="0 0 24 25" fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      style="width: 20px; height:20px; margin: 0px 20px">
                                      <g clip-path="url(#clip0_1001_10542)">
                                          <path
                                              d="M3 17.5338V20.5738C3 20.8538 3.22 21.0738 3.5 21.0738H6.54C6.67 21.0738 6.8 21.0238 6.89 20.9238L17.81 10.0138L14.06 6.26379L3.15 17.1738C3.05 17.2738 3 17.3938 3 17.5338ZM20.71 7.11379C21.1 6.72379 21.1 6.09379 20.71 5.70379L18.37 3.36379C17.98 2.97379 17.35 2.97379 16.96 3.36379L15.13 5.19379L18.88 8.94379L20.71 7.11379Z"
                                              fill="black" />
                                      </g>
                                      <defs>
                                          <clipPath id="clip0_1001_10542">
                                              <rect width="24" height="24" fill="white"
                                                  transform="translate(0 0.0742188)" />
                                          </clipPath>
                                      </defs>
                                  </svg>
                              </div>
                          </div>
                          <div *ngIf="!visible">
                              <div class="personal-info"><img src="images/icons/person_profile.png"
                                      class="icon">
                                      <span style="text-transform: capitalize;">{{user.basic_information.name.first_name || ""}}
                                        {{user.basic_information.name.last_name || ""}}</span>
                                  </div>
                              <div class="personal-info"><img src="images/icons/person_profile.png"
                                      class="icon">
                                  Username: {{user.user_name || 'Not given'}}</div>
                              <div class="personal-info" *ngIf="vaildDate"><img
                                      src="images/icons/cake_birthday.png" class="icon">
                                  {{user.basic_information.dob | date:'dd-MM-YYYY'}}</div>
                              <div class="personal-info"><img src="images/icons/person_profile.png"
                                      class="icon">
                                  {{getGender(user.basic_information.gender)}}</div>
                              <div class="flex sport-mobile" style="margin: .5rem;grid-gap:1rem;">
                                  <div *ngFor="let sport of user.sports ">
                                      <img src="images/icons/sports_soccer_profile.png" class="icon"
                                          style="transform: translateY(0%);">
                                      <span style="margin-left: 10px;">{{sport.sport_name || 'Not given'}}</span>
                                  </div>
                              </div>
                          </div>
                          <form class="form-sample" [formGroup]="editProfile">
                              <div class="w-100 profile-padding" *ngIf="visible">
                                  <div class="d-card w-100 d-flex">
                                      <div class="d-card w-100 d-flex">
                                          <div class="d-card-body d-flex flex-column w-100 justify-content-between">
                                              <div class="d-flex flex-column justify-content-between grid-gap w-100">
                                                  <div class="form-group row" style="margin-bottom: 0rem;">
                                                      <label class="col-sm-12 col-form-label">First name*</label>
                                                      <div class="col-sm-12">
                                                        <!-- <mat-form-field appearance="outline" class=""></mat-form-field> -->
                                                          <input type="text" class="form-control form-boxes"
                                                              name="fname" placeholder="First name"
                                                              formControlName="first_name"
                                                              (ngModelChange)="generateUsername();"
                                                              [(ngModel)]="user.basic_information.name.first_name">
                                                      </div>
                                                      <div class="col-sm-6 col-form-label input-group pt-2"
                                                          *ngIf="submitted && fnameValid">
                                                          <small class="text-danger">*First name is required.</small>
                                                      </div>
                                                  </div>
                                                  <div class="form-group row" style="margin-bottom: 0rem;">
                                                      <label class="col-sm-12 col-form-label">Last name</label>
                                                      <div class="col-sm-12">
                                                          <input type="text" class="form-control form-boxes"
                                                              name="lname" placeholder="Last name"
                                                              formControlName="last_name"
                                                              (ngModelChange)="generateUsername();"
                                                              [(ngModel)]="user.basic_information.name.last_name">
                                                      </div>
                                                      <!-- <div class="col-sm-6 col-form-label input-group pt-2" *ngIf="submitted && lnameValid">
                                                          <small class="text-danger">*Last name is required.</small>
                                                      </div> -->
                                                  </div>
                                                  <div class="form-group row" style="margin-bottom: 0rem;">
                                                      <label class="col-sm-12 col-form-label">Username*</label>
                                                      <div class="col-sm-12">
                                                          <input type="text" style="background-color: white;"
                                                              class="form-control form-boxes"
                                                              formControlName="user_name" [(ngModel)]="username"
                                                              name="username" (ngModelChange)="checkUserName()">
                                                      </div>
                                                  </div>

                                                  <div class="form-group row" style="margin-bottom: -1rem;">
                                                      <label class="col-sm-12 col-form-label">Date of birth*</label>
                                                      <div class="col-sm-12">
                                                          <mat-form-field appearance="outline" style="width: 100%;">
                                                              <input class="form-control" formControlName="date_of_birth" matInput
                                                                  [matDatepicker]="picker1" readonly
                                                                  [(ngModel)]="user.basic_information.dob"
                                                                  style="font-size: 0.8125rem; line-height: 25px; padding:0.5rem;">
                                                              <mat-datepicker-toggle matSuffix
                                                                  [for]="picker1"></mat-datepicker-toggle>
                                                              <mat-datepicker class="date-icon" #picker1></mat-datepicker>
                                                          </mat-form-field>
                                                          <p *ngIf="!vaildDate" class="no-date">Date of birth not set
                                                              yet, please set
                                                              one or default will be saved.</p>
                                                      </div>
                                                      <div class="col-sm-6 col-form-label input-group pt-2"
                                                          *ngIf="submitted && dateValid">
                                                          <small class="text-danger">*DOB cannot be later than the
                                                              todays date.</small>
                                                      </div>
                                                  </div>

                                                  <div class="form-group row" style="margin-bottom: 0rem;">
                                                      <label class="col-sm-12 col-form-label">Gender*</label>
                                                      <div class="col-sm-12">
                                                          <select class="form-control form-boxes"
                                                              formControlName="gender_"
                                                              [(ngModel)]="user.basic_information.gender"
                                                              name="gender" style="color: black;">
                                                              <!-- <option value="" selected hidden>Select Gender</option> -->
                                                              <option value="M">Male</option>
                                                              <option value="F">Female</option>
                                                              <option value="O">Others</option>
                                                          </select>
                                                      </div>
                                                  </div>

                                                  <div class="row mt-3">
                                                      <div class="col-md-6">
                                                          <div class="form-group row">
                                                              <div class="col-sm-12">
                                                                  <button matRipple type="button" class="btn btn-outline-dark main-height"
                                                                      (click)="personalEdit();"
                                                                      style="width: inherit;">Cancel</button>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div class="col-md-6">
                                                          <div class="form-group row">
                                                              <div class="col-sm-12">
                                                                  <button matRipple class="btn btn-primary main-height"
                                                                      (click)="editProfileInfo();"
                                                                      style="width: inherit;">Save</button>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </form>

                      </div>
                  </div>
                  <!-- parent info -->
                  <div class="col-lg-12 p-0"
                      *ngIf="(user?.parent_1_info?._id !== '000000000000000000000000') && (memberAccessControl.view_profile_parental_info.permissions | module: 'member management')">
                      <div class="card p-5">
                          <div style="display: flex; justify-content:space-between;">
                              <div class="header pb-4">Parent info</div>
                              <div style="cursor: pointer">
                                  <svg (click)="parentEdit()" width="24" height="25" viewBox="0 0 24 25" fill="none"
                                      *ngIf="memberAccessControl.edit_profile_parental_info.permissions | module: 'member management'"
                                      xmlns="http://www.w3.org/2000/svg"
                                      style="width: 20px; height:20px; margin: 0px 20px">
                                      <g clip-path="url(#clip0_1001_10542)">
                                          <path
                                              d="M3 17.5338V20.5738C3 20.8538 3.22 21.0738 3.5 21.0738H6.54C6.67 21.0738 6.8 21.0238 6.89 20.9238L17.81 10.0138L14.06 6.26379L3.15 17.1738C3.05 17.2738 3 17.3938 3 17.5338ZM20.71 7.11379C21.1 6.72379 21.1 6.09379 20.71 5.70379L18.37 3.36379C17.98 2.97379 17.35 2.97379 16.96 3.36379L15.13 5.19379L18.88 8.94379L20.71 7.11379Z"
                                              fill="black" />
                                      </g>
                                      <defs>
                                          <clipPath id="clip0_1001_10542">
                                              <rect width="24" height="24" fill="white"
                                                  transform="translate(0 0.0742188)" />
                                          </clipPath>
                                      </defs>
                                  </svg>
                              </div>
                          </div>
                          <div class="w-100 profile-padding">
                              <div class="d-card w-100 d-flex">
                                  <div class="d-card-body d-flex flex-column w-100 justify-content-between">
                                      <div class="feed-header mb-2">
                                          <div class="d-flex title-contain">
                                              <img src="{{user.parent_1_info?.basic_information?.dp}}"
                                                  class="profile-parent" (error)="onImgError($event)"
                                                  style="margin-right: 1rem;">
                                              <div class="d-flex flex-column justify-content-center w-100">
                                                  <div *ngIf="!parent_edit"
                                                      style="line-height: 10px; font-weight:600; text-transform: capitalize;" class="mt-1 mb-2">
                                                      {{user.parent_1_info?.basic_information?.name?.first_name ||
                                                      "-"}} {{user.parent_1_info?.basic_information?.name?.last_name
                                                      || ""}}</div>
                                                  <input *ngIf="parent_edit" class="form-control mb-2"
                                                      [(ngModel)]="user.parent_1_info.basic_information.name.first_name"
                                                      [ngModelOptions]="{standalone: true}" placeholder="First name">
                                                  <input *ngIf="parent_edit" class="form-control mb-2"
                                                      [(ngModel)]="user.parent_1_info.basic_information.name.last_name"
                                                      [ngModelOptions]="{standalone: true}" placeholder="Last name">
                                                  <input *ngIf="parent_edit" class="form-control mb-2"
                                                      [(ngModel)]="user.parent_1_info.basic_information.email"
                                                      [ngModelOptions]="{standalone: true}" placeholder="Email">

                                                  <div class="w-100 d-flex w-100 mb-2 mt-3" *ngIf="parent_edit" style="grid-gap: 1rem;">
                                                      <button class="btn btn-outline-dark main-height" (click)="parentEdit()">
                                                          Cancel
                                                      </button>
                                                      <button class="btn btn-primary main-height" (click)="updateParentEdit()">
                                                          Update
                                                      </button>
                                                  </div>
                                                  <div class="location-time text-sm f-light"
                                                      style="color: #959595; line-height: 20px;" *ngIf="!parent_edit">
                                                      <span *ngIf="user?.parent_concern?.is_approved">Consent
                                                          given</span>
                                                      <span *ngIf="!user?.parent_concern?.is_approved">Consent not
                                                          given</span>
                                                  </div>

                                              </div>
                                              <span *ngIf="!parent_edit" class="pointer resend" style="float: inline-end;" title="Resend Email" (click)="sendParentMail(user.parent_1_info._id, user._id)"><i class="bi bi-envelope-arrow-up"></i></span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>

                      </div>
                  </div>

                  <!-- nutrion -->
                  <div class="col-lg-12 p-0"
                      *ngIf="memberAccessControl.view_profile_nutrition.permissions | module: 'member management'">
                      <div class="card p-5">
                          <div style="display: flex; justify-content:space-between;">
                              <div class="header pb-4">Nutrition</div>
                              <div style="cursor: pointer">
                                  <svg width="24"
                                      *ngIf="memberAccessControl.edit_profile_nutrition.permissions | module: 'member management'"
                                      (click)="preferenceEdit();" height="25" viewBox="0 0 24 25" fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      style="width: 20px; height:20px; margin: 0px 20px">
                                      <g clip-path="url(#clip0_1001_10542)">
                                          <path
                                              d="M3 17.5338V20.5738C3 20.8538 3.22 21.0738 3.5 21.0738H6.54C6.67 21.0738 6.8 21.0238 6.89 20.9238L17.81 10.0138L14.06 6.26379L3.15 17.1738C3.05 17.2738 3 17.3938 3 17.5338ZM20.71 7.11379C21.1 6.72379 21.1 6.09379 20.71 5.70379L18.37 3.36379C17.98 2.97379 17.35 2.97379 16.96 3.36379L15.13 5.19379L18.88 8.94379L20.71 7.11379Z"
                                              fill="black" />
                                      </g>
                                      <defs>
                                          <clipPath id="clip0_1001_10542">
                                              <rect width="24" height="24" fill="white"
                                                  transform="translate(0 0.0742188)" />
                                          </clipPath>
                                      </defs>
                                  </svg>
                              </div>
                          </div>
                          <div class="d-flex flex-column justify-content-between grid-gap w-100">
                              <div class="d-flex justify-content-between" style="grid-gap: 1rem;">
                                  <div class="col-md-12">
                                      <div class="form-group row">
                                          <div class="w-100 d-flex mt-3">
                                              <div class="text-bold mb-2 mt-1">
                                                  Need nutritionist
                                              </div>
                                              <div class="check-box mb-2 mt-1 ml-2">
                                                  <input type="checkbox"
                                                      (change)="checkboxNutrition(user.basic_information.is_nutritionist_needed)"
                                                      [(ngModel)]="user.basic_information.is_nutritionist_needed"
                                                      [ngModelOptions]="{standalone: true}"
                                                      style="width: 1.5rem; height: 1.5rem; margin-left: 1rem;">

                                              </div>
                                          </div>

                                          <div class="w-100" *ngIf="!preference">
                                              <div class="d-flex mt-2">
                                                  <div class="text-bold mb-2">Food preferences</div>
                                              </div>
                                              <div class="d-flex flex-wrap mt-2">
                                                  <div class="food-box mb-2"
                                                      *ngFor="let foods of user?.basic_information?.prefered_foods; let i=index">
                                                      {{foods}}
                                                  </div>
                                              </div>
                                              <div class="no-data2"
                                                  *ngIf="!user.basic_information?.prefered_foods || user.basic_information?.prefered_foods.length === 0">
                                                  No food items.
                                              </div>
                                          </div>

                                          <form class="form-sample w-100" [formGroup]="preferedFoods">
                                              <div *ngIf="preference">
                                                  <div class="d-flex justify-content-between mt-2">
                                                      <div class="text-base mb-2">Food preferences</div>
                                                  </div>
                                                  <div class="col-md-12">
                                                      <div class="form-group row">
                                                          <div class="w-100">
                                                              <input type="text" formControlName="prefered_foods"
                                                                  style=" font-size:16px;" class="form-control"
                                                                  name="lname" placeholder="Enter food preference" />
                                                          </div>

                                                      </div>
                                                  </div>
                                                  <div class="d-flex flex-wrap mt-3">
                                                      <div class="food-box mb-2"
                                                          *ngFor="let foods of user?.basic_information?.prefered_foods; let i=index">
                                                          <span>{{foods}}</span>
                                                          <img src="images/icons/ic_close_red.png"
                                                              (click)="deleteFoods(i)"
                                                              style="width: 1.5rem; height: 1.5rem; cursor:pointer; margin-left: 0.5rem;">
                                                      </div>
                                                  </div>
                                                  <div class="col-md-12">
                                                      <div class="form-group row">
                                                          <label class="col-sm-12 col-form-label"></label>
                                                          <div class="w-100">
                                                              <button matRipple class="btn btn-primary main-height"
                                                                  (click)=" preferenceEdit(); preferedFoodsList();"
                                                                  style="width: inherit;">Save</button>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </form>
                                      </div>
                                  </div>
                              </div>
                          </div>

                      </div>
                  </div>

                  <div class="col-lg-12 p-0"
                      *ngIf="memberAccessControl.view_profile_associated_detail.permissions | module: 'member management'">
                      <div class="card p-5">
                          <div class="header pb-4">Associated groups</div>
                          <div class="group pb-4" *ngIf="members.length > 0">
                              <div class="group-text mb-2" *ngFor="let member of members.slice(0,a_g_limit);"><img
                                      src="{{member.dp}}" class="profile-pic" (error)="onImgError($event)">
                                  {{member.name}}</div>
                          </div>
                          <div class="col-12 no-data" *ngIf="members.length === 0">
                              No associated groups
                          </div>
                          <button class="btn btn-outline-primary load-more" *ngIf="members.length > a_g_limit"
                              (click)="a_g_limit=members.length">View +{{members.length - a_g_limit}} more</button>


                          <div class="header pb-4">Associated Coaches</div>
                          <div class="group pb-4" *ngIf="coachs?.length > 0">
                              <div class="group-text mb-2" *ngFor="let coach of coachs.slice(0,a_c_limit);"><img
                                      src="{{coach.dp}}" class="profile-pic" (error)="onImgError($event)">
                                  {{coach.name}}</div>
                          </div>
                          <div class="col-12 no-data" *ngIf="coachs?.length === 0">
                              No associated coaches
                          </div>
                          <button class="btn btn-outline-primary load-more" *ngIf="coachs?.length > a_c_limit"
                              (click)="a_c_limit=coachs.length">View
                              +{{coachs.length - a_c_limit}} more</button>


                          <div class="header pb-4">Associated sports</div>
                          <div class="group pb-4" *ngIf="sports?.length > 0">
                              <div *ngFor="let sport of sports.slice(0,a_s_limit);" class="sport-contain mb-2"><img
                                      src="images/icons/ic_sport_football.png" class="profile-pic"
                                      (error)="onImgError($event)">
                                  {{sport.sport_name}}</div>
                          </div>
                          <div class="col-12 no-data" *ngIf="sports?.length === 0">
                              No associate sports
                          </div>
                          <button class="btn btn-outline-primary load-more" *ngIf="sports?.length > a_s_limit"
                              (click)="a_s_limit=members.length">View
                              +{{sports.length - a_s_limit}} more</button>
                      </div>
                  </div>

                  <!-- <div class="col-lg-12 p-0 ">
                      <div class="card p-5">
                          <div class="header pb-4">Homeworks</div>
                          <div class="header secondary pb-4">Pending</div>
                          <div class="homework-container">
                              <div class="homework-element d-flex flex-column justify-content-between p-4 cursor-pointer"
                                  *ngFor="let homework of homework_pending | slice:0:2;"
                                  [routerLink]="['/lesson/homework/individual/' + homework.classid + '/' + homework._id]">
                                  <div class="w-100">
                                      <div class="homework-header">{{homework.name}}</div>
                                      <div class="homework-text mb-3 mt-1">Type :
                                          <b>{{homework.type}}</b>
                                      </div>
                                      <div class="homework-text-secondary">
                                          <ul>
                                              <li *ngFor="let act of homework.tasks | slice:0:2;">
                                                  {{act.task}}</li>
                                          </ul>
                                      </div>
                                  </div>
                                  <div class="homework-text-footer mt-3">End Date:
                                      <b>{{homework.end_date |
                                          date:'dd/MM/YYYY'}}</b>
                                  </div>
                              </div>
                          </div>

                          <div *ngIf="homework_pending">
                              <div class="col-12 no-data" *ngIf="homework_pending.length === 0">
                                  No Pending Homeworks
                              </div>
                              <div class="w-100 d-flex justify-content-center">
                                  <button class="btn btn-outline-primary load-more mt-5"
                                      *ngIf="homework_pending.length>2">View More</button>
                              </div>
                          </div>
                      </div>
                  </div> -->

                  <!-- <div class="col-lg-12 p-0">
                      <div class="card p-5">
                          <div class="header pb-4">Attendence</div>
                          <div class="attendence mb-4">
                              <div class="flex flex-c">
                                  <div>
                                      <div class="attendence-hours mb-3">
                                          {{
                                          calcPercentage(attendenceDetails?.present_sessions,attendenceDetails?.total_sessions)
                                          }}%
                                      </div>
                                      <div class="flex flex-c">
                                          <div class="attendence-secondary bold">Total Attendence Percentage</div>
                                          <div class="attendence-secondary" *ngIf="attendenceDetails?.earliest_time">
                                              {{attendenceDetails?.earliest_time | date:'YYYY | MMMM | dd'}} -
                                              Present</div>
                                      </div>
                                  </div>
                                  <div class="dev-fraction-text">
                                      <div>
                                          <span class="dev-fraction">{{attendenceDetails?.present_sessions || 0}} /
                                              {{attendenceDetails?.total_sessions || 0}}</span> Attendence
                                      </div>
                                  </div>
                              </div>
                              <div class="d-flex flex-column justify-content-between align-items-end">
                                  <img src="images/icons/dool_calender.png" class="attendence-dool">
                                  <button class="btn btn-outline-primary load-more bg-w m-0"
                                      [routerLink]="['/calender']"
                                      [queryParams]="{ type: 'student', id: userid, academy: id }">View
                                      Attendence</button>
                              </div>
                          </div>
                          <div *ngIf="attendenceDetails" class="w-100 d-flex justify-content-center">
                              <button class="btn btn-outline-primary load-more"
                                  *ngIf="(attendenceDetails?.data || []).length > 2">View More</button>
                          </div>
                          <div class="col-12 no-data" *ngIf="(attendenceDetails?.data || []).length === 0">
                              <p>No Pending Attendence</p>
                          </div>
                      </div>
                  </div> -->
              </div>
              <div class="col-lg-6 p-0 flex flex-c  grid-gap" style="height: fit-content;">

                  <!-- Physical Characteristics -->
                  <div class="col-lg-12 flex-elem p-0">
                      <div class="card">
                          <div class="card-body">
                              <div class="w-100">
                                  <div class="w-100" style="display: flex; justify-content:space-between;">
                                      <div class="bold header">
                                          Physical characteristics
                                      </div>
                                      <div style="cursor: pointer">
                                          <svg (click)="characteristicsEdit()" width="24" height="25"
                                              viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"
                                              style="width: 20px; height:20px; margin: 0px 20px">
                                              <g clip-path="url(#clip0_1001_10542)">
                                                  <path
                                                      d="M3 17.5338V20.5738C3 20.8538 3.22 21.0738 3.5 21.0738H6.54C6.67 21.0738 6.8 21.0238 6.89 20.9238L17.81 10.0138L14.06 6.26379L3.15 17.1738C3.05 17.2738 3 17.3938 3 17.5338ZM20.71 7.11379C21.1 6.72379 21.1 6.09379 20.71 5.70379L18.37 3.36379C17.98 2.97379 17.35 2.97379 16.96 3.36379L15.13 5.19379L18.88 8.94379L20.71 7.11379Z"
                                                      fill="black" />
                                              </g>
                                              <defs>
                                                  <clipPath id="clip0_1001_10542">
                                                      <rect width="24" height="24" fill="white"
                                                          transform="translate(0 0.0742188)" />
                                                  </clipPath>
                                              </defs>
                                          </svg>
                                      </div>
                                  </div>
                                  <div class="w-100 stats-contain mt-3" *ngIf="!characteristics_edit">
                                      <div class="d-flex stats flex-column">
                                          Height
                                          <span>{{(user.basic_information?.height ||
                                              profile_report.basic_information?.height)|| "-"}} <span *ngIf="(user.basic_information?.height ||
                                              profile_report.basic_information?.height)">cm</span></span>
                                      </div>
                                      <div class="d-flex stats flex-column">
                                          Weight
                                          <span>{{(user.basic_information?.weight ||
                                              profile_report.basic_information?.weight)|| "-"}} <span *ngIf="(user.basic_information?.weight ||
                                              profile_report.basic_information?.weight)">kg</span></span>
                                      </div>
                                      <div class="d-flex stats flex-column">
                                          BMI
                                          <span>{{((user.basic_information?.bmi ||
                                              profile_report.basic_information?.bmi)| number:'1.2-2')||
                                              "-"}}</span>
                                      </div>
                                  </div>
                                  <div class="w-100 stats-contain mt-3" *ngIf="characteristics_edit">
                                      <div class="d-flex stats flex-column">
                                          Height
                                          <span class="mt-2">
                                              <input type="number" min="0" class="form-control mb-2 main-height" (change)="onKeyDownBMI($event)"
                                                  [(ngModel)]="user.basic_information.height"
                                                  [ngModelOptions]="{standalone: true}" placeholder="Height"
                                                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"> cm
                                          </span>
                                      </div>
                                      <div class="d-flex stats flex-column">
                                          Weight
                                          <span class="mt-2">
                                              <input type="number" min="0" class="form-control mb-2 main-height" (change)="onKeyDownBMI($event)"
                                                  [(ngModel)]="user.basic_information.weight"
                                                  [ngModelOptions]="{standalone: true}" placeholder="Weight"
                                                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"> kg
                                          </span>
                                      </div>
                                      <div class="d-flex stats flex-column">
                                          BMI
                                          <span>
                                              <span>{{((user.basic_information?.bmi ||
                                                  profile_report.basic_information?.bmi)| number:'1.2-2')||
                                                  "-"}}</span>
                                          </span>
                                      </div>
                                  </div>
                                  <button matRipple class="btn btn-primary w-100 main-height mt-3" *ngIf="characteristics_edit"
                                      (click)="updateBios()">Save</button>
                              </div>
                          </div>
                      </div>
                  </div>

                  <!-- contact info -->
                  <div class="col-lg-12 flex-elem p-0"
                      *ngIf="memberAccessControl.view_profile_contact_info.permissions | module: 'member management'">
                      <div class="card p-5">
                          <div style="display: flex; justify-content:space-between;">
                              <div class="header pb-4">Contact info</div>
                              <div style="cursor: pointer">
                                  <svg (click)="contactEdit()" width="24" height="25" viewBox="0 0 24 25" fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      style="width: 20px; height:20px; margin: 0px 20px">
                                      <g clip-path="url(#clip0_1001_10542)">
                                          <path
                                              d="M3 17.5338V20.5738C3 20.8538 3.22 21.0738 3.5 21.0738H6.54C6.67 21.0738 6.8 21.0238 6.89 20.9238L17.81 10.0138L14.06 6.26379L3.15 17.1738C3.05 17.2738 3 17.3938 3 17.5338ZM20.71 7.11379C21.1 6.72379 21.1 6.09379 20.71 5.70379L18.37 3.36379C17.98 2.97379 17.35 2.97379 16.96 3.36379L15.13 5.19379L18.88 8.94379L20.71 7.11379Z"
                                              fill="black" />
                                      </g>
                                      <defs>
                                          <clipPath id="clip0_1001_10542">
                                              <rect width="24" height="24" fill="white"
                                                  transform="translate(0 0.0742188)" />
                                          </clipPath>
                                      </defs>
                                  </svg>
                              </div>
                          </div>

                          <div *ngIf="!contact_edit">
                              <div class="personal-info" *ngIf="phone"><img src="images/icons/profile_call.png"
                                      class="icon">
                                  {{phone.country_code}} {{phone.number|| "Not given"}}
                              </div>
                              <div class="personal-info" *ngIf="phone === null"><img
                                      src="images/icons/profile_call.png" class="icon">Not given
                              </div>
                              <div class="personal-info"><img src="images/icons/profile_mail.png" class="icon">
                                  {{user.basic_information.email|| "Not given"}}</div>
                              <div class="personal-info"><img src="images/icons/profile_mail.png" class="icon">
                                  {{user.basic_information.alternative_email|| "Not given"}}</div>
                              <div class="personal-info" *ngIf="user.basic_information.address">
                                  <img src="images/icons/profile_location.png" class="icon">
                                  <p style="text-transform: capitalize;" *ngIf="!addressEmpty">
                                      <span
                                          *ngIf="user.basic_information.address[0].addr1.length > 0">{{user.basic_information.address[0].addr1}},</span>
                                      <span
                                          *ngIf="user.basic_information.address[0].city.length > 0">{{user.basic_information.address[0].city}},</span><br>
                                      <span
                                          *ngIf="user.basic_information.address[0].district.length > 0">{{user.basic_information.address[0].district}},</span>
                                      <span
                                          *ngIf="user.basic_information.address[0].pincode.length > 0">{{user.basic_information.address[0].pincode}},</span><br>
                                      <span
                                          *ngIf="user.basic_information.address[0].state.length > 0">{{user.basic_information.address[0].state}},</span>
                                  </p>
                                  <p style="text-transform: capitalize;" *ngIf="addressEmpty">No address given</p>
                              </div>
                          </div>
                          <div *ngIf="contact_edit">
                              <div class="personal-info"><img src="images/icons/profile_call.png" class="icon">
                                  <!-- <input class="form-control mb-2" (keydown)="onKeyDown($event)"
                                      [(ngModel)]="phone.country_code" [ngModelOptions]="{standalone: true}"
                                      placeholder="Country code"> -->
                                      <mat-form-field appearance="outline" class="">
                                        <input type="text" class="main-height"
                                               placeholder="pick a country code"
                                               matInput
                                               [formControl]="phoneCodeControl"
                                               [matAutocomplete]="autophoneCodeControl">
                                        <mat-autocomplete #autophoneCodeControl="matAutocomplete">
                                          @for (option of filteredOptionsPhoneCodes | async ; track option) {
                                            <mat-option [value]="option.dial_code">{{option.dial_code}} ({{option.name}})</mat-option>
                                          }
                                        </mat-autocomplete>
                                      </mat-form-field>
                              </div>
                              <div class="personal-info"><img src="images/icons/profile_call.png" class="icon">
                                <mat-form-field appearance="outline" class="">
                                  <input matInput (input)="onPhoneNumberInput($event)"
                                  (keydown)="onKeyDown($event)" [(ngModel)]="phone.number"
                                  [ngModelOptions]="{standalone: true}" placeholder="Phone number">
                                </mat-form-field>
                              </div>
                              <div class="personal-info"><img src="images/icons/profile_mail.png" class="icon">
                                <mat-form-field appearance="outline" class="">
                                  <input matInput
                                  [(ngModel)]="user.basic_information.alternative_email"
                                  [ngModelOptions]="{standalone: true}" placeholder="Alternative email">
                                </mat-form-field>
                              </div>
                              <div class="personal-info">
                                  <img src="images/icons/profile_location.png" class="icon">
                                  <p style="text-transform: capitalize;" class="d-flex flex-column">
                                      <mat-form-field appearance="outline" class="">
                                          <input matInput
                                              [(ngModel)]="user.basic_information.address[0].addr1"
                                              [ngModelOptions]="{standalone: true}" placeholder="Address">
                                      </mat-form-field>
                                      <mat-form-field appearance="outline" class="">
                                          <input matInput
                                              [(ngModel)]="user.basic_information.address[0].district"
                                              [ngModelOptions]="{standalone: true}" placeholder="District">
                                      </mat-form-field>
                                      <mat-form-field appearance="outline" class="">
                                          <input matInput
                                              [(ngModel)]="user.basic_information.address[0].city"
                                              [ngModelOptions]="{standalone: true}" placeholder="City">
                                      </mat-form-field>
                                      <mat-form-field appearance="outline" class="">
                                          <input matInput
                                              [(ngModel)]="user.basic_information.address[0].state"
                                              [ngModelOptions]="{standalone: true}" placeholder="State">
                                      </mat-form-field>
                                      <mat-form-field appearance="outline" class="">
                                          <input matInput
                                              [(ngModel)]="user.basic_information.address[0].pincode"
                                              [ngModelOptions]="{standalone: true}" placeholder="Pincode">
                                      </mat-form-field>
                                  </p>
                              </div>

                              <div class="row">
                                <div class="col-6">
                                    <div class="form-group row">
                                        <div class="col-sm-12">
                                            <button matRipple class="btn btn-outline-dark w-100 main-height" (click)="contactEdit()">Cancel</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group row">
                                        <div class="col-sm-12">
                                            <button matRipple class="btn btn-primary w-100 main-height" (click)="editContactDetails()">Save</button>
                                        </div>
                                    </div>
                                </div>
                              </div>
                          </div>

                      </div>
                  </div>

                  <!-- education -->
                  <div class="col-lg-12 flex-elem p-0"
                      *ngIf="memberAccessControl.view_profile_education_quali.permissions | module: 'member management'">
                      <div class="card p-5">
                          <div *ngIf="!educationalQ">
                              <div style="display: flex; justify-content:space-between;">
                                  <div class="header pb-4">Educational qualifications</div>
                                  <img src="images/icons/add_circle_profile.png"
                                      *ngIf="memberAccessControl.create_profile_education_quali.permissions | module: 'member management'"
                                      (click)="educationalQuali();"
                                      style="width: 1.2rem; height: 1.2rem; cursor:pointer; margin-left: 0.5rem;">
                              </div>
                              <div class="w-100">
                                  <div class="education-box" *ngFor="let educations of education; let i=index">
                                      <div style="float: right;">
                                          <div class="dropdown clickable" tabindex="{{i}}">
                                              <i class="bi bi-three-dots-vertical dropbtn marginl ft-24"></i>
                                              <div class="dropdown-content">
                                                  <div *ngIf="memberAccessControl.delete_profile_education_quali.permissions | module: 'member management'"
                                                      [swal]="warningMultiDelete">
                                                      Delete
                                                  </div>
                                                  <swal #warningMultiDelete type="warning" title="Are you sure ?" confirmButtonText="Confirm"
                                                      text="This will delete education details from the profile."
                                                      [showCancelButton]="true" cancelButtonClass="btn btn-outline-dark"
                                                      (confirm)="deleteEducation(i)">
                                                  </swal>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="ml-2 over-flow-word" style="padding: 0.3rem;">
                                          <div class="text-bold mb-1" [title]="educations.university_name">
                                              {{educations.university_name | string : 'sentence'}}</div>
                                          <div class="text-bold mb-3" style="color: #959595;">{{educations.year_in}} -
                                              {{educations.year_out}}</div>
                                          <div class="text-base f-bold mb-1" [title]="educations.course"
                                              style="color: #959595;">
                                              {{educations.course | string : 'sentence'}}</div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div
                              *ngIf="(educational === null && !educationalQ) || (educational.length === 0 && !educationalQ)">
                              <span class="no-data">
                                  Educations not added yet.
                              </span>
                          </div>
                          <form class="form-sample" [formGroup]="addEducation">
                              <div class="w-100 profile-padding" *ngIf="educationalQ">
                                  <div class="d-card w-100 d-flex">
                                      <div class="d-card w-100 d-flex">
                                          <div class="d-card-body d-flex flex-column w-100 justify-content-between">
                                              <div class="header pb-5">Add educational qualification</div>
                                              <div class="d-flex flex-column justify-content-between grid-gap w-100">
                                                  <div class="form-group row" style="margin-bottom: 0rem;">
                                                      <div class="col-sm-12">
                                                          <input type="text" formControlName="university"
                                                              class="form-control form-boxes" name="fname"
                                                              placeholder="University" />
                                                      </div>
                                                  </div>
                                                  <div class="form-group row" style="margin-bottom: 0rem;">
                                                      <div class="col-sm-12">
                                                          <input type="text" formControlName="course"
                                                              class="form-control form-boxes" name="fname"
                                                              placeholder="Course" />
                                                      </div>
                                                  </div>
                                                  <div class="row">
                                                      <div class="col-md-6">
                                                          <div class="form-group row">
                                                              <div class="col-sm-12">
                                                                  <input type="number" formControlName="year_in"
                                                                      class="form-control form-boxes" name="fname"
                                                                      placeholder="Year in"
                                                                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                                      min="1900" max="2900"

                                                                      (change)="validateStartYear()"/>
                                                                      <!-- [(ngModel)]="addEducation.controls['year_in'].value" [ngModelOptions]="{standalone: true}" -->
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div class="col-md-6">
                                                          <div class="form-group row">
                                                              <!-- <label class="col-sm-12 col-form-label">Weight</label> -->
                                                              <div class="col-sm-12">
                                                                  <input type="number" formControlName="year_out"
                                                                      class="form-control form-boxes" name="lname"
                                                                      placeholder="Year out"
                                                                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                                                                      min="1900" max="2900"
                                                                      (change)="validateEndYear()"/>
                                                                      <!-- [(ngModel)]="addEducation.controls['year_out'].value" [ngModelOptions]="{standalone: true}" -->
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="row">
                                                      <div class="col-md-6">
                                                          <div class="form-group row">
                                                              <div class="col-sm-12">
                                                                  <button type="button" class="btn btn-outline-dark main-height"
                                                                      (click)="educationalQuali();"
                                                                      style="width: inherit;">Cancel</button>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div class="col-md-6">
                                                          <div class="form-group row">
                                                              <div class="col-sm-12">
                                                                  <button class="btn btn-primary main-height"
                                                                      [disabled]="createClick"
                                                                      (click)="createEducation();"
                                                                      style="width: inherit;">Save</button>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </form>
                      </div>
                  </div>

                  <!-- Achievements -->
                  <!-- <div class="col-lg-12 p-0 ">
                      <div class="card p-5">
                          <div class="header pb-4">Achievements</div>
                          <div *ngFor="let achivements of achievement.basic_information.achievement | slice:0:ach_limit;"
                              class="achievement">
                              <div class="achievement-text mb-2" title="{{achivements.name}} : {{achivements.desc}}">
                                  <img src="images/icons/ic_cup_orange.png" class="icon"
                                      style="transform: translateY(0%);margin-right: .5rem;">
                                  {{achivements.name}} : {{achivements.desc}}
                              </div>
                              <div class="achievement-text-secondary">{{achivements.date| date:'MMM | dd | YYYY'}}
                              </div>
                          </div>
                          <div class="col-12 no-data" *ngIf="achievement.basic_information.achievement.length === 0">
                              No Achivements
                          </div>

                          <button class="btn btn-outline-primary load-more"
                              *ngIf="achievement.basic_information.achievement.length > ach_limit"
                              (click)="ach_limit=achievement.basic_information.achievement.length">View
                              +{{achievement.basic_information.achievement.length - ach_limit}} More</button>
                      </div>
                  </div> -->

                  <!-- <div class="col-lg-12 flex-elem p-0">
                      <div class="card p-5">
                          <div class="header pb-4">Academic Report</div>
                          <div class="grade-graph mb-4 p-3">
                              <h5 class="text-primary mb-4">Grade Graph</h5>
                              <div class="w-100 mb-4">
                                  <div class="row d-flex align-items-center mb-3">
                                      <div class="col-4">
                                          Select Year
                                      </div>
                                      <div class="col-8">
                                          <mat-form-field appearance="outline" class="w-100" (click)="picker.open()">
                                              <input matInput [matDatepicker]="picker" readonly
                                                  style="font-size: 0.8125rem;padding: .4375rem .75rem;"
                                                  [(ngModel)]="selectedYear" [max]="maxYear">
                                              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                              <mat-datepicker startView="multi-year" #picker
                                                  (yearSelected)="selectedYearHandler($event, picker)"></mat-datepicker>
                                          </mat-form-field>
                                      </div>
                                  </div>
                                  <div class="row d-flex align-items-center">
                                      <div class="col-4">
                                          Term
                                      </div>
                                      <div class="col-8">
                                          <select class="form-control border-primary" [(ngModel)]="selectedTerm"
                                              (change)="updateTerm()"
                                              [disabled]="!this.academicStats?.academics?.course?.term?.length">
                                              <option *ngIf="!this.academicStats?.academics?.course?.term?.length"
                                                  value="">No choices available</option>
                                              <option [value]="term.name"
                                                  *ngFor="let term of this.academicStats?.academics?.course?.term">
                                                  {{term.name}}
                                              </option>
                                          </select>
                                      </div>
                                  </div>
                              </div>
                              <div class="w-100">
                                  <ng-container *ngIf="academicStats && totalSubjectsCount > 0">
                                      <ng-container
                                          *ngFor="let subject of (academicStats?.academics?.course?.subjects || [])">
                                          <div class="w-100 mb-3"
                                              *ngIf="subject?.target_details?.term === selectedTerm">
                                              <div class="w-100 d-flex justify-content-end mb-1">
                                                  <span
                                                      class="border border-secondary d-flex justify-content-center sub-g-summary">
                                                      <span>
                                                          <span
                                                              class="text-primary">{{subject.target_details.current_score}}</span>
                                                          <span class="text-secondary pl-3 pr-3">|</span>
                                                          <span class="text-secondary">{{subject.target_grade}}</span>
                                                      </span>
                                                  </span>
                                              </div>
                                              <div class="row d-flex align-items-center">
                                                  <div class="col-4 overflow-hidden">
                                                      <div [title]="subject.name"
                                                          class="font-weight-bold grade-subject">
                                                          {{subject.name}}</div>
                                                  </div>
                                                  <div class="col-8">
                                                      <div class="progress-contain">
                                                          <div class="progress progress-sm">
                                                              <div class="progress-bar bg-primary" role="progressbar"
                                                                  [ngStyle]="{'width.%': subject.target_details.current_grade_offset }"
                                                                  aria-valuemin="0" aria-valuemax="100">
                                                                  <div class="ring" [ngStyle]="{
                                                                          'left.%': subject.target_details.current_grade_offset
                                                                      } "
                                                                      *ngIf="subject.target_details.current_score">
                                                                  </div>
                                                                  <div class="ring border-secondary" [ngStyle]="{
                                                                          'left.%': subject.target_details.target_grade_offset - (subject.target_details.current_score === subject.target_grade ? 1.5 : 0),
                                                                          'width.px':
                                                                              subject.target_details.current_score === subject.target_grade ? 22 : 15,
                                                                          'height.px': subject.target_details.current_score === subject.target_grade ? 22 : 15,
                                                                          'z-index': 5
                                                                      }">
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </ng-container>
                                      <div class="w-100 mb-5">
                                          <div class="row d-flex justify-content-end">
                                              <div class="col-8 d-flex justify-content-between">
                                                  <small
                                                      *ngFor="let grade of (academicStats?.academics?.course?.scoring?.grades?.reverse() || [])">
                                                      {{grade.grade}}
                                                  </small>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="w-100 d-flex justify-content-around mb-4">
                                          <div
                                              class="col-5 d-flex flex-column justify-content-center bg-white pt-4 pb-4">
                                              <small class="mb-2 d-flex align-items-center">
                                                  <div class="mr-1 bg-primary rounded-circle grade-info"></div>
                                                  Attained Grade
                                              </small>
                                              <small class="mb-0 d-flex align-items-center">
                                                  <div class="mr-1 bg-secondary rounded-circle grade-info"></div>
                                                  Target Grade
                                              </small>
                                          </div>
                                          <div
                                              class="col-5 d-flex align-items-center justify-content-center bg-white">
                                              <h6 class="text-primary text-center">
                                                  {{targetAchievedCountForGrades}}/{{(totalSubjectsCount)
                                                  || 0}} on target
                                              </h6>
                                          </div>
                                      </div>
                                  </ng-container>
                                  <div class="col-12 no-data"
                                      *ngIf="(!academicStats || totalSubjectsCount === 0) && !academicStatsLoading">
                                      No Records Found
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div> -->

                  <!-- <div class="col-lg-12 flex-elem p-0">
                      <div class="card p-5">
                          <div class="d-flex">
                              <div class="header pb-4">Personnel development Sessions</div>
                              <div class="d-flex flex-column" style="padding-left: 2rem;" *ngIf="classes?.length>0">
                                  <div ngbDropdown style="width: 20vw;">
                                      <button class="btn btn-light btn-outline-dark col-12" type=" button"
                                          id="dropdownMenuOutlineButton1" ngbDropdownToggle>
                                          {{class_name}}
                                      </button>
                                      <div ngbDropdownMenu aria-labelledby="dropdownMenuOutlineButton1">
                                          <div *ngFor="let class of classes;let last = last">
                                              <a class="dropdown-item"
                                                  (click)="setCurrentClass(class)">{{class.group_info.basic_information.name.first_name}}</a>
                                              <div class="dropdown-divider" *ngIf="!last"></div>
                                          </div>
                                          <div class="no-group-report-dropdown" *ngIf="classes.length === 0">
                                              No groups added.
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="development mb-4">
                              <div class="flex flex-c justify-content-between">
                                  <div>
                                      <div class="dev-hours">{{dev_hours || 0}}</div>
                                      <div class="flex flex-c">
                                          <div class="dev-secondary" *ngIf="pdStats?.earliest_time">
                                              {{pdStats.earliest_time |
                                              date:'YYYY | MMMM | dd' || 'No date'}} - Present
                                          </div>
                                      </div>
                                  </div>
                                  <div class="dev-fraction-text">
                                      <div>
                                          <span class="dev-fraction">{{pdStats?.attended_sessions || 0}} /
                                              {{pdStats?.data?.length || 0}}</span> Sessions
                                      </div>
                                  </div>
                              </div>
                              <div class="flex flex-c justify-content-between">
                                  <img src="images/icons/dool_watch.png" class="dev-dool">
                                  <button class="btn btn-outline-primary mt-4" [routerLink]="['/calender']"
                                      [queryParams]="{ type: 'student', id: userid, academy: id }">View
                                      Sessions</button>
                              </div>
                          </div>
                          <ng-container *ngFor="let personalDevCategory of pdStatSessions">
                              <div class="header mb-4">{{personalDevCategory.type}}</div>
                              <div class="development-sub-container mb-4">
                                  <div class="development-sub p-4 cursor-pointer"
                                      *ngFor="let personal_dev of personalDevCategory.data | slice:0:2;"
                                      [routerLink]="['/lesson/pd/' + personal_dev.classid + '/' + personal_dev.pd_info._id]">
                                      <div class="dev-sub-header text-capitalize">
                                          {{personal_dev.pd_info.session_name || 'Untitled Session'}}
                                      </div>
                                      <div class="dev-sub-text text-capitalize mt-4 mb-2">
                                          {{personal_dev.pd_info.objectives}}
                                      </div>
                                      <div class="flex flex-c">
                                          <span class="homework-text-footer">Date :
                                              <b>{{personal_dev.pd_info.start_date |
                                                  date:'MMM d'}}</b></span>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-12 no-data" *ngIf="personalDevCategory.data.length === 0">
                                  <p>No {{personalDevCategory.type}}</p>
                              </div>
                              <div class="w-100 d-flex justify-content-center"
                                  *ngIf="personalDevCategory.data.length >2">
                                  <button class="btn btn-outline-primary load-more mb-5">View More</button>
                              </div>
                          </ng-container>
                      </div>
                  </div> -->

                  <!-- <div class="col-lg-12 flex-elem p-0">
                      <div class="row mob">
                          <div class="col-lg-12 grid-margin stretch-card">
                              <div class="col-lg-12 flex-elem p-0">
                                  <div class="card p-5">
                                      <div class="header pb-4 w-100 d-flex justify-content-between">
                                          <span>Gallery</span>
                                          <p class="text-secondary cursor-pointer"
                                              [routerLink]="['/member/profile/' + id + '/gallery']">
                                              View All
                                          </p>
                                      </div>

                                      <div class="gallery-contain-filter mb-4">
                                          <div class="type-filter">
                                              <div class="gallery-header">Type:</div>
                                              <div class="upload-contain">
                                                  <ul ngbNav [activeId]="1">
                                                      <li [ngbNavItem]="1">
                                                          <a ngbNavLink class="item mr-3"
                                                              (click)="filterGallery('')">All</a>
                                                      </li>
                                                      <li [ngbNavItem]="2">
                                                          <a ngbNavLink class="item mr-3"
                                                              (click)="filterGallery('image')">Image</a>
                                                      </li>
                                                      <li [ngbNavItem]="3">
                                                          <a ngbNavLink class="item"
                                                              (click)="filterGallery('video')">Video</a>
                                                      </li>
                                                  </ul>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="gallery">
                                          <ng-container *ngIf="user_feed_loading">
                                              <div class="gallery-item shimmer" *ngFor="let _ of [1,2,3,4,5,6]"></div>
                                          </ng-container>
                                          <div *ngFor="let feeds of (user_feed || []).slice(0,6)"
                                              class="d-block position-relative">
                                              <div
                                                  [ngClass]="{'image': feeds.uploads[0].type === 'image', 'video' : feeds.uploads[0].type === 'video'}">
                                              </div>
                                              <img src="{{feeds.uploads[0].thumbnail_url}}" class="gallery-item"
                                                  title="{{feeds.uploads[0].type}}">
                                          </div>
                                      </div>
                                      <div class="col-12 no-data" *ngIf="user_feed === null">
                                          No Posts in the Feed
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div> -->

              </div>
          </div>
      </div>

  </div>
</div>
