<!-- eslint-disable @angular-eslint/template/alt-text -->
<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div style="padding: 1rem 2rem;">
  <div class="page-header" style="margin: 1.5rem 0 1.5rem 0; display: flex; justify-content: center;">
      <div style="margin: auto;font-size: 2rem;">
          <img [src]="academy_dp" class="profile-pic me-3" style="height: 3rem;">
          <span class="ml-2 bd-7">{{academy_name||'Academy Name'}}</span>
      </div>
      <!-- <div>
          <img src="images/logo-mini.svg" class="profile-pic">
          Dartle
      </div> -->
  </div>

  <div class="card mt-3" *ngIf="current_step === 0">
      <div class="card-body">
        <form class="form-sample" [formGroup]="addPersonalInfo" (ngSubmit)="onSubmit()">
          <div class="row">
            <div class="col-12">
              <div class="w-100" *ngIf="!editMode">
                <div class="confirm-contain col-12 p-0">
                  <div class="col-lg-4" style="grid-gap: 3rem;">
                    <span class="d-flex">
                      <div class="number-round primary-bg">
                        <span class="bd-7">1</span>
                      </div>
                      <span class="ml-3 mt-1" style="font-size: 24px; color: #FF6634; width: 8rem;">Player info</span>
                    </span>
                    <span class="d-flex">
                      <div class="number-round secondary-bg">
                        <span class="bd-7" style="color: white;">2</span>
                      </div>
                      <span class="ml-3 mt-1" style="font-size: 24px; color: #D7D7D7;">Confirmation</span>
                    </span>
                  </div>
                </div>
                <div class="d-flex col-12 p-0">
                  <div class="col-6 p-0 primary-half"></div>
                  <div class="col-6 p-0 secondary-half"></div>
                </div>
              </div>
              <div class="row mt-3" style="margin: 0;">
                <div class="header-players mt-3 mb-4 col-lg-6 p-0">Personal info (Compulsory)</div>
                <!-- <div class="col-lg-6 p-0" style="display: flex; justify-content: flex-end;">
                  <button class="btn bd-7 btn-outline-primary" style="text-decoration: none;height: 48px; border-radius: 5px; white-space: nowrap;">
                    Import player data
                  </button>
                </div> -->
              </div>
              <!-- <form class="form-sample" [formGroup]="addPersonalInfo" (ngSubmit)="onSubmit()"> -->
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group row">
                    <label class="col-sm-12 col-form-label">Email</label>
                    <div class="col-sm-12">
                      <input type="text" formControlName="email" class="form-control" name="email"
                        placeholder="email@example.com" (change)="checkUser()"/>
                    </div>
                    <div class="col-sm-12 col-form-label input-group pt-2" *ngIf="submitted && addPersonalInfo.controls['email'].invalid">
                      <!-- <small class="text-danger" *ngIf="addPersonalInfo.controls['email'].errors?.required">*Email is required.</small> -->
                      <small class="text-danger" *ngIf="addPersonalInfo.controls['email'].errors?.email">*Enter a valid email address.</small>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group row">
                    <label class="col-sm-12 col-form-label">Date of birth*</label>
                    <div class="col-sm-12">
                      <mat-form-field appearance="outline" style="width: 100%;">
                        <input matInput [matDatepicker]="picker" readonly placeholder="Select date"
                         (dateChange)="checkAge()"
                         style="font-size: 0.8125rem; padding: .4375rem .75rem;" formControlName="dob">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                      </mat-form-field>
                      <p style="color: #9b9b9b; font-size: .85rem; margin: 0.2rem;">Hint: DD/MM/YYYY</p>
                    </div>
                    <div class="col-sm-12 col-form-label input-group pt-2" *ngIf="submitted && addPersonalInfo.controls['dob'].invalid">
                      <small class="text-danger">*Date of birth is required.</small>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group row">
                    <label class="col-sm-12 col-form-label">First name*</label>
                    <div class="col-sm-12">
                      <input type="text" class="form-control" (input)="generateUsername()" name="fname" placeholder="Enter first name" formControlName="fname">
                    </div>
                    <div class="col-sm-12 col-form-label input-group pt-2" *ngIf="submitted && addPersonalInfo.controls['fname'].invalid">
                      <small class="text-danger">*First name is required.</small>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group row">
                    <label class="col-sm-12 col-form-label">Last name</label>
                    <div class="col-sm-12">
                      <input type="text" class="form-control" (input)="generateUsername()" formControlName="lname" name="lname" placeholder="Enter last name" />
                    </div>
                    <!-- <div class="col-sm-12 col-form-label input-group pt-2" *ngIf="submitted && addPersonalInfo.controls['lname'].invalid">
                      <small class="text-danger">*Last Name is required.</small>
                    </div> -->
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group row">
                    <label class="col-sm-12 col-form-label">Username*</label>
                    <div class="col-sm-12">
                      <input type="text" class="form-control" formControlName="username"
                        (input)="checkUserName()"  [(ngModel)]="username"
                        name="username" placeholder="username" />
                    </div>
                    <div class="col-sm-12 col-form-label input-group pt-2" *ngIf="usernameValid && submitted && addPersonalInfo.controls['username'].invalid">
                      <small class="text-danger">*Username is required.</small>
                    </div>
                    <div class="col-sm-12 col-form-label input-group pt-2" *ngIf="submitted && usernameValid && !addPersonalInfo.controls['username'].invalid">
                      <small class="text-danger">*Username already exist.</small>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group row">
                    <label class="col-sm-12 col-form-label">Gender*</label>
                    <div class="col-sm-12">
                      <select class="form-control" formControlName="gender" name="gender" style="color: black;">
                        <option value="" selected hidden>Select gender</option>
                        <option value="M">Male</option>
                        <option value="F">Female</option>
                        <option value="O">Others</option>
                      </select>
                    </div>
                    <div class="col-sm-12 col-form-label input-group pt-2" *ngIf="submitted && addPersonalInfo.controls['gender'].invalid">
                      <small class="text-danger">*Gender is required.</small>
                    </div>
                  </div>
                </div>

                <div class="col-md-6" *ngIf="!isUnder13">
                  <div class="form-group row">
                    <label class="col-sm-12 col-form-label">Membership plan</label>
                    <div class="col-sm-12">
                      <select class="form-control" formControlName="plan" name="plan" style="color: black;" (change)="onPlanChange($event)">
                        <option value="" selected hidden>Select membership plan</option>
                        <option *ngFor="let member of memberships" [value]="member._id">{{ member.name || member | string: 'sentence' }}</option>
                        <option value="remove">Remove membership plan</option>
                      </select>
                    </div>
                    <!-- <div class="col-sm-12 col-form-label input-group pt-2" *ngIf="submitted && addPersonalInfo.controls['plan'].invalid">
                      <small class="text-danger">*Membership plan is required.</small>
                    </div> -->
                  </div>
                </div>
              </div>
              <!-- </form> -->


              <!-- <form class="form-sample"> -->
              <div class="header-players mt-5 col-lg-6 p-0 mb-0">Parent/Guardian info
                <span  *ngIf="isUnder13">(Compulsory)</span>
                <span  *ngIf="!isUnder13">(Optional)</span>
              </div>
              <div class="col-sm-12 p-0 col-form-label input-group mb-3">
                  <small class="text-danger"  *ngIf="isUnder13">*Parent/Guardian info is compulsory for players under 13</small>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group row">
                    <label class="col-sm-12 col-form-label">Email*</label>
                    <div class="col-sm-12">
                      <input type="text" class="form-control" formControlName="pemail" placeholder="email@example.com" />
                    </div>
                    <div class="col-sm-12 col-form-label input-group pt-2" *ngIf="submitted && addPersonalInfo.controls['pemail'].invalid">
                      <small class="text-danger" *ngIf="addPersonalInfo.controls['pemail'].errors?.required">*Email is required.</small>
                      <small class="text-danger" *ngIf="addPersonalInfo.controls['pemail'].errors?.email">*Enter valid email address.</small>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group row">
                        <label class="col-sm-12 col-form-label">First name*</label>
                        <div class="col-sm-12">
                            <input type="text" class="form-control" formControlName="pfname" placeholder="Enter first name" />
                        </div>
                        <div class="col-sm-12 col-form-label input-group pt-2" *ngIf="submitted && addPersonalInfo.controls['pfname'].invalid">
                            <small class="text-danger">*First name is required.</small>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group row">
                        <label class="col-sm-12 col-form-label">Last name</label>
                        <div class="col-sm-12">
                            <input type="text" class="form-control" formControlName="plname" name="plname" placeholder="Enter last name" />
                        </div>
                    </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group row">
                      <label class="col-sm-12 col-form-label">Phone number*</label>
                      <div class="col-sm-12">
                          <input class="form-control" formControlName="pphonenumber"
                            (input)="onPhoneNumberInput($event)" (keydown)="onKeyDown($event)"
                            placeholder="+91 99988 77665" />
                      </div>
                      <div class="col-sm-12 col-form-label input-group pt-2" *ngIf="submitted && addPersonalInfo.controls['pphonenumber'].invalid">
                          <small class="text-danger" *ngIf="addPersonalInfo.controls['pphonenumber'].errors?.pattern">*Please check the format of the Phone number.</small>
                          <small class="text-danger" *ngIf="addPersonalInfo.controls['pphonenumber'].errors?.required">*Please enter a Phone number.</small>
                      </div>
                  </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group row">
                        <label class="col-sm-12 col-form-label">Relation</label>
                        <div class="col-sm-12">
                            <input type="text" class="form-control" formControlName="prelation" name="prelation" placeholder="Parent" />
                        </div>
                    </div>
                </div>
              </div>
              <!-- </form> -->
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
                <div class="header-players mt-1 mb-5 col-lg-6 p-0">Licenses & Certificates (Optional)</div>
                <div class="uploading-contain col-12 mb-5">
                  <div class="media-container-empty" (click)="openMediumModal(exampleModalContent)">
                    <div class="upload-contain">
                      <img src="images/icons/upload.png" style="height: 2rem;">
                      <span class="primary-text bd-7">Upload your file</span>
                      <span class="primary-text">Files supported: JPG, PNG, PDF</span>
                    </div>
                  </div>
                  <div class="col-12 p-0 middle" *ngIf="licenseList.length > 0" style="max-height: 300px; overflow: auto;">
                    <div class="media-container col-12" style="cursor: pointer;"  *ngFor="let license of licenseList; let i = index">
                      <div class="class-text d-flex">
                        <div (click)="openAttachment(license.path)" class="d-flex col-7 p-0 over-flow-word">
                          <span>
                            <img src="images/icons-svg/dartle_emblem.svg" class="dartle-logo">
                          </span>
                          <span class="class-text-name mb-2 over-flow-word" [title]="license.name">{{license.name}}</span>
                        </div>
                        <div class="col-5 media-delete">
                          <!-- <span style="color: #9E9E9E; font-size: 20px;">50 KB</span> -->
                          <img src="images/icons/remove_circle.png" [swal]="warningWithCancel"
                            style="width: 24px; height: 28px;">
                        </div>
                        <swal #warningWithCancel type="warning" title="Are you sure ?" confirmButtonText="Confirm"
                                  text="Do you want to remove this attachment" [showCancelButton]="true"
                                  cancelButtonClass="btn btn-outline-dark" (confirm)="removeAttachment(license.path)">
                              </swal>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-3 mt-5" *ngIf="current_step === 0 && show_additional_info">
                  <div class="col-12 mb-3 d-flex pe-5" style="gap: 2rem;">
                    <div class="col-lg-10 d-flex flex-column p-0 fit-width" style="height: fit-content; gap: 2rem; max-height: 800px; overflow: auto;" #leftContainer>
                      <div id="card1" #card1 class="col-lg-12 p-0">
                        <div class="card">
                          <div class="card-body">
                            <div class="header-players mt-1 mb-4 col-lg-6 p-0">Physical characteristics</div>
                            <form class="form-sample" [formGroup]="addPhysicalChara">
                              <div class="w-100 profile-padding">
                                  <div class="d-card w-100 d-flex">
                                      <div class="d-card w-100 d-flex">
                                          <div class="d-card-body d-flex flex-column w-100 justify-content-between">
                                              <div class="d-flex flex-column justify-content-between grid-gap w-100">
                                                  <div class="row show-style">
                                                      <div class="col-md-4" >
                                                          <span>Height (Optional)</span>
                                                          <div class="form-group row mt-2">
                                                              <div class="col-sm-12">
                                                                  <input type="number" class="form-control form-boxes" formControlName="height" placeholder="cm" min="0" maxlength="3" />
                                                                  <div *ngIf="addPhysicalChara.get('height').invalid && addPhysicalChara.get('height').touched" class="text-danger">
                                                                      *Invalid height
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div class="col-md-4">
                                                          <span>Weight (Optional)</span>
                                                          <div class="form-group row mt-2">
                                                              <div class="col-sm-12">
                                                                  <input type="number" class="form-control form-boxes" formControlName="weight" placeholder="kg" min="0" maxlength="3" />
                                                                  <div *ngIf="addPhysicalChara.get('weight').invalid && addPhysicalChara.get('weight').touched" class="text-danger">
                                                                      *Invalid weight
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div class="col-md-4">
                                                          <span>BMI (Optional)</span>
                                                          <div class="form-group row mt-2">
                                                              <div class="col-sm-12">
                                                                  <input type="text" class="form-control form-boxes" formControlName="bmi" placeholder="kg/m²" readonly/>
                                                                  <div *ngIf="addPhysicalChara.get('bmi').invalid && addPhysicalChara.get('bmi').touched" class="text-danger">
                                                                     *Invalid BMI
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div id="card2" #card2 class="col-lg-12 p-0">
                        <div class="card">
                          <div class="card-body">
                            <div class="header-players mt-1 mb-4 col-lg-6 p-0">Sport info</div>
                            <form class="form-sample" [formGroup]="addSportInfo">
                              <div class="w-100 profile-padding">
                                  <div class="d-card w-100 d-flex">
                                      <div class="d-card w-100 d-flex">
                                          <div class="d-card-body d-flex flex-column w-100 justify-content-between">
                                              <div class="d-flex flex-column justify-content-between grid-gap w-100">
                                                <div class="row show-style">
                                                  <div class="col-md-6" >
                                                      <span>Sport</span>
                                                      <div class="form-group row mt-2">
                                                        <div class="col-sm-12">
                                                          <select class="form-control" formControlName="sport_name" name="sport_name" style="color: black;">
                                                            <option value="" selected hidden>Select sport</option>
                                                            <option *ngFor="let sport of sportsList" [value]="sport._id">
                                                              {{ sport.sports | string : 'sentence' }}
                                                              @if (sport.alias) {
                                                                ({{sport.alias}})
                                                              }
                                                            </option>
                                                          </select>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <span>Position</span>
                                                        <div class="form-group row mt-2">
                                                            <div class="col-sm-12">
                                                                <input type="text" formControlName="position"
                                                                    class="form-control form-boxes" name="position"
                                                                    placeholder="Enter here" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div id="card3" #card3 class="col-lg-12 p-0">
                        <div class="card">
                          <div class="card-body">
                            <div class="header-players mt-1 mb-4 col-lg-6 p-0">Address info</div>
                            <form class="form-sample" [formGroup]="addAddressInfo">
                              <div class="w-100 profile-padding">
                                  <div class="d-card w-100 d-flex">
                                      <div class="d-card w-100 d-flex">
                                          <div class="d-card-body d-flex flex-column w-100 justify-content-between">
                                              <div class="d-flex flex-column justify-content-between grid-gap w-100">
                                                <div class="mb-2ss">
                                                  <span>Country</span>
                                                  <div class="form-group row mt-2">
                                                    <div class="col-sm-12">
                                                        <div ngbDropdown>
                                                            <button class="btn form-control btn-outline-dark" type="button"
                                                                id="dropdownClass" ngbDropdownToggle
                                                                style="text-align: left;padding: 0rem 1rem;border: 1px solid #ced4da;">
                                                                {{countryName}}
                                                            </button>
                                                            <div ngbDropdownMenu aria-labelledby="dropdownClass" style="min-width: 100%;">
                                                                <input type="text" class="form-control filter-search"
                                                                    [(ngModel)]="searchTerm" [ngModelOptions]="{standalone: true}"
                                                                    placeholder="Country name" (input)="search()" />
                                                                <div>
                                                                    <div class="dropdown-item filter-elem"
                                                                        *ngFor="let elem of countryList  | slice:0:25;"
                                                                        (click)="selectCountry(elem)">
                                                                        {{elem.country}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="row show-style">
                                                  <div class="col-md-6" >
                                                    <span>Address</span>
                                                    <div class="form-group row mt-2">
                                                        <div class="col-sm-12">
                                                            <input type="text" formControlName="address"
                                                                class="form-control form-boxes" name="fname"
                                                                placeholder="Enter here" />
                                                        </div>
                                                    </div>
                                                  </div>
                                                  <div class="col-md-6">
                                                    <span>County/Province/State</span>
                                                    <div class="form-group row mt-2">
                                                        <div class="col-sm-12">
                                                            <div ngbDropdown>
                                                                <button class="btn form-control btn-outline-dark" type="button"
                                                                    id="dropdownClass" ngbDropdownToggle
                                                                    style="text-align: left;padding: 0rem 1rem;border: 1px solid #ced4da;">
                                                                    {{stateName}}
                                                                </button>
                                                                <div ngbDropdownMenu aria-labelledby="dropdownClass" style="min-width: 100%;">
                                                                    <input type="text" class="form-control filter-search"
                                                                        [(ngModel)]="searchTermState" [ngModelOptions]="{standalone: true}"
                                                                        placeholder="State name" (input)="searchState()" />
                                                                    <div>
                                                                        <div class="dropdown-item filter-elem" *ngFor="let elem of stateList"
                                                                            (click)="selectState(elem)">
                                                                            {{elem}}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="row show-style">
                                                    <div class="col-md-6" >
                                                      <span>City</span>
                                                      <div class="form-group row mt-2">
                                                          <div class="col-sm-12">
                                                              <input type="text" class="form-control" formControlName="city" name="city"
                                                                  placeholder="Enter city" />
                                                          </div>
                                                      </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                      <span>Post/Zip Code</span>
                                                      <div class="form-group row mt-2">
                                                          <div class="col-sm-12">
                                                              <div ngbDropdown>
                                                                  <button class="btn form-control btn-outline-dark" type="button"
                                                                      id="dropdownClass" ngbDropdownToggle
                                                                      style="text-align: left;padding: 0rem 1rem;border: 1px solid #ced4da;">
                                                                      {{pincodeName}}
                                                                  </button>
                                                                  <div ngbDropdownMenu aria-labelledby="dropdownClass" style="min-width: 100%;">
                                                                      <input type="text" class="form-control filter-search"
                                                                          [(ngModel)]="searchTermPincode" [ngModelOptions]="{standalone: true}"
                                                                          placeholder="State name" (input)="searchPostcode()" />
                                                                      <div>
                                                                          <div class="dropdown-item filter-elem"
                                                                              *ngFor="let elem of pincodeList | slice:0:25;"
                                                                              (click)="selectPostcode(elem)">
                                                                              {{elem}}
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                    </div>
                                                </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div id="card4" #card4 class="col-lg-12 p-0">
                        <div class="card">
                          <div class="card-body">
                            <div class="header-players mt-1 mb-4 col-lg-6 p-0">Educational history</div>
                            <div class="card p-3 mb-5">
                              <form class="form-sample" [formGroup]="addEducation">
                                <div class="w-100 profile-padding">
                                  <div class="d-card w-100 d-flex">
                                    <div class="d-card w-100 d-flex">
                                      <div class="d-card-body d-flex flex-column w-100 justify-content-between">
                                        <div class="d-flex flex-column justify-content-between grid-gap w-100">
                                          <span>Education institute</span>
                                          <div class="form-group row mt-2" style="margin-bottom: 0rem;">
                                            <div class="col-sm-12">
                                              <input type="text" formControlName="institute" class="form-control form-boxes" placeholder="Institute" />
                                            </div>
                                          </div>
                                          <div class="row mt-3 mb-3 show-style">
                                            <div class="col-md-6" >
                                              <span>Discipline</span>
                                              <div class="form-group row mt-2">
                                                <div class="col-sm-12">
                                                  <input type="text" formControlName="discipline" class="form-control form-boxes" placeholder="Enter here" />
                                                </div>
                                              </div>
                                            </div>
                                            <div class="col-md-3">
                                              <span>Start year</span>
                                              <div class="form-group row mt-2">
                                                <div class="col-sm-12">
                                                  <input type="number" min="1900" formControlName="start_Year" class="form-control form-boxes" placeholder="Start year" maxlength="4"  />
                                                  <div *ngIf="addEducation.get('start_Year').invalid && addEducation.get('start_Year').touched" class="text-danger">
                                                    *Invalid start year
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="col-md-3">
                                              <span>End year</span>
                                              <div class="form-group row mt-2">
                                                <div class="col-sm-12">
                                                  <input type="number" min="1901" formControlName="end_Year" class="form-control form-boxes" placeholder="End year" maxlength="4" />
                                                  <div *ngIf="addEducation.get('end_Year').invalid && addEducation.get('end_Year').touched" class="text-danger">
                                                    *Invalid end year
                                                  </div>
                                                  <div *ngIf="addEducation.errors?.startYearLessThanEndYear" class="text-danger">
                                                    *End year should be greater than start year
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="col-md-3 p-0">
                                            <div class="form-group row">
                                              <div class="col-sm-12">
                                                <button matRipple class="btn btn-primary main-height" [disabled]="!checkFormValidity()" (click)="createEducation()"
                                                        style="width: inherit; padding: 0.8rem 3rem 0.8rem 3rem; min-width: 10rem;">Save</button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>


                            </div>
                            <div class="card p-4" *ngIf="educationList.length > 0" style="background: #F7F7F7;">
                              <div class="w-100 profile-padding">
                                <div class="d-card w-100 d-flex">
                                    <div class="d-card w-100 d-flex">
                                        <div class="d-card-body d-flex flex-column w-100 justify-content-between">
                                            <div class="d-flex flex-column justify-content-between grid-gap w-100">
                                                <span class="header-players ml-4">Education history</span>
                                                <div class="col-12 mt-3 mb-3">
                                                  <div class="row">
                                                    <div class="col-md-6 show-style" *ngFor="let educations of educationList; let i=index">
                                                      <div class="education-box">
                                                          <div style="float: right; cursor: pointer;" (click)="removeEducation(i)">
                                                            <img src="images/icons/remove_circle.png" style="width: 24px; height: 28px;">
                                                          </div>
                                                          <div class="d-flex">
                                                            <img class="mt-1" src="images/icons/school.png" style="width: 24px; height: 28px;">
                                                            <div class="ms-2 over-flow-word" style="padding: .3rem;">
                                                              <div class="text-bold mb-1 over-flow-word" style="text-transform: capitalize; color: #FF6634;">
                                                                {{educations.institute}}</div>
                                                              <div class="text-base f-bold mb-1 over-flow-word"
                                                                  style="color: #959595;">
                                                                  {{educations.discipline | string : 'sentence'}}</div>
                                                              <div class="text-bold mb-1">
                                                                {{educations.start_date}} - {{educations.end_date}}</div>
                                                            </div>
                                                          </div>

                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="card5" #card5 class="col-lg-12 p-0">
                        <div class="card">
                          <div class="card-body">
                            <div class="header-players mt-1 mb-4 col-lg-6 p-0">Careers</div>
                            <div class="card p-3 mb-5">
                              <form class="form-sample" [formGroup]="addCareer">
                                <div class="w-100 profile-padding">
                                  <div class="d-card w-100 d-flex">
                                    <div class="d-card w-100 d-flex">
                                      <div class="d-card-body d-flex flex-column w-100 justify-content-between">
                                        <div class="d-flex flex-column justify-content-between grid-gap w-100">
                                          <span>Academy</span>
                                          <div class="form-group row mt-2" style="margin-bottom: 0rem;">
                                            <div class="col-sm-12">
                                              <input type="text" formControlName="academy" class="form-control form-boxes" placeholder="Enter here" />
                                            </div>
                                          </div>
                                          <div class="row mt-3 mb-3 show-style">
                                            <div class="col-md-6" >
                                              <span>Role</span>
                                              <div class="form-group row mt-2">
                                                <div class="col-sm-12">
                                                  <input type="text" formControlName="role" class="form-control form-boxes" placeholder="Enter here" />
                                                </div>
                                              </div>
                                            </div>
                                            <div class="col-md-3">
                                              <span>Start date</span>
                                              <div class="form-group row mt-2">
                                                <div class="col-sm-12">
                                                  <mat-form-field appearance="outline" style="width: 100%;">
                                                    <input matInput [matDatepicker]="picker1" readonly placeholder="Select date"
                                                           style="font-size: 0.8125rem; padding: .4375rem .75rem;" formControlName="start_date">
                                                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                                    <mat-datepicker #picker1></mat-datepicker>
                                                  </mat-form-field>
                                                  <div *ngIf="addCareer.get('start_date').invalid && addCareer.get('start_date').touched" class="text-danger">
                                                    *Invalid start date
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="col-md-3">
                                              <span>End date</span>
                                              <div class="form-group row mt-2">
                                                <div class="col-sm-12">
                                                  <mat-form-field appearance="outline" style="width: 100%;">
                                                    <input matInput [matDatepicker]="picker2" readonly placeholder="Select date"
                                                           style="font-size: 0.8125rem; padding: .4375rem .75rem;" formControlName="end_date">
                                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                                    <mat-datepicker #picker2></mat-datepicker>
                                                  </mat-form-field>
                                                  <div *ngIf="addCareer.get('end_date').invalid && addCareer.get('end_date').touched" class="text-danger">
                                                    *Invalid end date
                                                  </div>
                                                  <div *ngIf="addCareer.errors?.startDateBeforeEndDate" class="text-danger">
                                                    *End date should be after start date
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="col-md-3 p-0">
                                            <div class="form-group row">
                                              <div class="col-sm-12"> 
                                                <button matRipple class="btn btn-primary main-height" [disabled]="!checkCareerValidity()" (click)="createCareer()"
                                                        style="width: inherit; padding: 0.8rem 3rem 0.8rem 3rem; min-width: 10rem;">Save</button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>

                            </div>
                            <div class="card p-4" *ngIf="careerList.length > 0" style="background: #F7F7F7;">
                              <div class="w-100 profile-padding">
                                <div class="d-card w-100 d-flex">
                                    <div class="d-card w-100 d-flex">
                                        <div class="d-card-body d-flex flex-column w-100 justify-content-between">
                                            <div class="d-flex flex-column justify-content-between grid-gap w-100">
                                                <span class="header-players ml-4">Career history</span>
                                                <div class="col-12 mt-3 mb-3">
                                                  <div class="row">
                                                    <div class="col-md-6 show-style" *ngFor="let career of careerList; let i=index">
                                                      <div class="education-box">
                                                          <div style="float: right; cursor: pointer;" (click)="removeCareer(i)">
                                                            <img src="images/icons/remove_circle.png" style="width: 24px; height: 28px;">
                                                          </div>
                                                          <div class="d-flex">
                                                            <img class="mt-1" src="images/icons/work.png" style="width: 24px; height: 28px;">
                                                            <div class="ms-2 over-flow-word" style="padding: .3rem;">
                                                              <div class="text-bold mb-1 over-flow-word" style="text-transform: capitalize; color: #FF6634;">
                                                                {{career.academy_name}}</div>
                                                              <div class="text-base f-bold mb-1 over-flow-word"
                                                                  style="color: #959595;">
                                                                  {{career.role | string: 'sentence'}}</div>
                                                              <div class="text-bold mb-1">
                                                                {{ career.start_date | dateTime: 'MMMM yyyy' }} - {{ career.end_date | dateTime: 'MMM yyyy' }}</div>
                                                            </div>
                                                          </div>

                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div id="card6" #card6 class="col-lg-12 p-0">
                        <div class="card">
                          <div class="card-body">
                            <div class="header-players mt-1 mb-4 col-lg-6 p-0">Achievements</div>
                            <div class="card p-3 mb-5">
                              <form class="form-sample" [formGroup]="addAchievement">
                                <div class="w-100 profile-padding">
                                  <div class="d-card w-100 d-flex">
                                    <div class="d-card w-100 d-flex">
                                      <div class="d-card-body d-flex flex-column w-100 justify-content-between">
                                        <div class="d-flex flex-column justify-content-between grid-gap w-100">
                                          <span>Event</span>
                                          <div class="form-group row mt-2" style="margin-bottom: 0rem;">
                                            <div class="col-sm-12">
                                              <input type="text" formControlName="event" class="form-control form-boxes" name="event" placeholder="Event" />
                                              <div *ngIf="addAchievement.get('event').invalid && addAchievement.get('event').touched" class="text-danger">
                                                *Event is required
                                              </div>
                                            </div>
                                          </div>
                                          <div class="row mt-3 mb-3 show-style">
                                            <div class="col-md-6" >
                                              <span>Achievements</span>
                                              <div class="form-group row mt-2">
                                                <div class="col-sm-12">
                                                  <input type="text" formControlName="achievement" class="form-control form-boxes" name="achievement" placeholder="Enter here" />
                                                  <div *ngIf="addAchievement.get('achievement').invalid && addAchievement.get('achievement').touched" class="text-danger">
                                                    *Achievement is required
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="col-md-6">
                                              <span>Year</span>
                                              <div class="form-group row mt-2">
                                                <div class="col-sm-12">
                                                  <input type="number" min="1900" formControlName="year" class="form-control form-boxes" placeholder="Year" maxlength="4" />
                                                  <div *ngIf="addAchievement.get('year').invalid && addAchievement.get('year').touched" class="text-danger">
                                                    *Invalid year
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="col-md-3 p-0">
                                            <div class="form-group row">
                                              <div class="col-sm-12">
                                                <button matRipple class="btn btn-primary main-height" [disabled]="!checkAchievementValidity()" (click)="saveAchievement()" style="width: inherit; padding: 0.8rem 3rem 0.8rem 3rem; min-width: 10rem;">
                                                  Save
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>

                            </div>
                            <div class="card p-4" *ngIf="achievementList.length > 0" style="background: #F7F7F7;">
                              <div class="w-100 profile-padding">
                                <div class="d-card w-100 d-flex">
                                    <div class="d-card w-100 d-flex">
                                        <div class="d-card-body d-flex flex-column w-100 justify-content-between">
                                            <div class="d-flex flex-column justify-content-between grid-gap w-100">
                                                <span class="header-players ml-4">Achievement list</span>
                                                <div class="col-12 mt-3 mb-3">
                                                  <div class="row">
                                                    <div class="col-md-6 show-style" *ngFor="let achieve of achievementList; let i=index">
                                                      <div class="education-box">
                                                          <div style="float: right; cursor: pointer;" (click)="removeAchievement(i)">
                                                            <img src="images/icons/remove_circle.png" style="width: 24px; height: 28px;">
                                                          </div>
                                                          <div class="d-flex">
                                                            <img class="mt-1" src="images/icons/stars.png" style="width: 24px; height: 28px;">
                                                            <div class="ms-2 over-flow-word" style="padding: .3rem;">
                                                              <div class="text-bold mb-1 over-flow-word" style="text-transform: capitalize; color: #FF6634;">
                                                                {{achieve.event_name}}</div>
                                                              <div class="text-base f-bold mb-1 over-flow-word"
                                                                  style="color: #959595;">
                                                                  {{achieve.achievement | string:'sentence'}}</div>
                                                              <div class="text-bold mb-1">
                                                                {{achieve.date}}</div>
                                                            </div>
                                                          </div>

                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="web-show h-100 col-lg-2 p-0 show-selection">
                      <div class="card d-flex flex-column p-0" style="height: 800px;">
                        <div class="text-selection-box" (click)="scrollToCard('card1')">
                          Physical characteristics
                        </div>
                        <div class="text-selection-box" (click)="scrollToCard('card2')">
                          Sport info
                        </div>
                        <div class="text-selection-box" (click)="scrollToCard('card3')">
                          Address info
                        </div>
                        <div class="text-selection-box" (click)="scrollToCard('card4')">
                          Educational history
                        </div>
                        <div class="text-selection-box" (click)="scrollToCard('card5')">
                          Careers
                        </div>
                        <div class="text-selection-box" (click)="scrollToCard('card6')">
                          Achievements
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="row mt-3" style="margin: 0;">
                  <div class="additional-players mt-1 mb-5 col-lg-6 p-0" (click)="showAdditional()">Additional info (Optional)
                    <img *ngIf="!show_additional_info" src="images/icons/keyboard_arrow_down.png" style="width: 24px; height: 28px;">
                  </div>
                  <div class="col-lg-6 p-0" style="display: flex; justify-content: flex-end;" *ngIf="!editMode">
                    <button matRipple class="btn bd-7 btn-primary main-height" style="text-decoration: none; border-radius: 5px; white-space: nowrap;
                      padding: 1rem 2.5rem; display: flex; align-items: center;">
                      Save player data
                    </button>
                  </div>
                </div>
            </div>
          </div>
        </form>
      </div>
  </div>


  <div class="card mt-3" *ngIf="current_step === 0 && !editMode">
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <div class="row mt-3" style="margin: 0;">
            <div class="additional-players mt-1 mb-3 col-lg-6 p-0" style="font-size: 22px;">{{playersAddedList.length}} Players added</div>
            <div class="col-lg-6 p-0" style="display: flex; justify-content: flex-end;">
              <button matRipple class="btn bd-7 btn-primary main-height" [disabled]="playersAddedList.length === 0" (click)="nextStep()" style="text-decoration: none; padding: 1rem 5rem;
                 border-radius: 5px; white-space: nowrap; align-items: center; display: flex;">
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card mt-3" *ngIf="current_step === 1">
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <div class="w-100">
            <div class="confirm-contain col-12 p-0">
              <div class="col-lg-4" style="grid-gap: 3rem;">
                <span class="d-flex">
                  <div class="number-round primary-bg">
                    <span class="bd-7">1</span>
                  </div>
                  <span class="ml-3 mt-1" style="font-size: 24px; color: #FF6634; width: 8rem;">Player info</span>
                </span>
                <span class="d-flex">
                  <div class="number-round primary-bg">
                    <span class="bd-7">2</span>
                  </div>
                  <span class="ml-3 mt-1" style="font-size: 24px; color: #FF6634;">Confirmation</span>
                </span>
              </div>
            </div>
            <div class="d-flex col-12 p-0">
              <div class="col-6 p-0 primary-half"></div>
              <div class="col-6 p-0 primary-half"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card mt-3" *ngIf="current_step === 1">
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <div class="row mt-3" style="margin: 0;">
            <div class="header-players mt-1 mb-5 col-lg-6 p-0">Player list ({{playersAddedList.length}} players)</div>
            <div class="col-lg-6 p-0" style="display: flex; justify-content: flex-end;">
              <!-- <button class="btn bd-7 btn-outline-primary" style="text-decoration: none;height: 48px; border-radius: 5px; white-space: nowrap;">
                Import player data
              </button> -->
            </div>
          </div>

          <div *ngFor="let player of playersAddedList; let i = index">
            <div class="student-contain">
                <div class="web-show number-list">{{i+1}}</div>
                <div class="d-flex justify-content-between">
                  <div class="profile">
                    <img class="profile-pic" [src]="player.student_info?.basic_information?.dp" (error)="onImgError($event)">
                    <div style="margin: auto 0;">
                        <div class="title">
                            {{player.first_name }} {{player.last_name || "" }}
                        </div>
                        <div class="academy-font" style="color: #969696;">
                          <span *ngIf="player.gender === 'M'">Male</span>
                          <span *ngIf="player.gender === 'F'">Female</span>
                          <span *ngIf="player.gender === 'O'">Others</span>
                          | {{player.dob}}
                        </div>
                    </div>
                  </div>
                  <span class="mobile-show col-2">
                    <div class="d-flex justify-content-between align-items-center" style="cursor: pointer;">
                      <img src="images/icons/edit.png" (click)="editIndividualMember(i)" style="width: 24px; height: 28px;">
                      <img src="images/icons/remove_circle.png" (click)="removeSelectedMember(i)" style="width: 24px; height: 28px;">
                    </div>
                  </span>

                </div>

                <hr class="mobile-show  horizontal">
                <div class="d-flex justify-content-between">
                  <div style="display: flex; align-items: center;">
                    <div ngbDropdown>
                      <button class="btn form-control over-flow-word main-height" [disabled]="isUnder13" type="button" id="dropdownClass" ngbDropdownToggle
                        style="text-align: left; padding: 0rem 1rem; border: 1px solid #ced4da; height: 41px; margin: .25em 0; width: 8rem; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                        title="">
                        {{ getMembershipName(player.product_role) || "Select plan" }}
                      </button>
                      <div class="main-height over-flow-word" ngbDropdownMenu aria-labelledby="dropdownClass" style="min-width: 100%;">
                        <div *ngFor="let member of memberships; let i = index" (click)="editPlan(i, member)" class="dropdown-item membership">
                          {{member.name | string:'sentence'}}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class=" d-flex align-items-center">
                    <div class="d-flex">
                      <img src="images/icons/attach_file.png" style="width: 24px; height: 26px;">
                      <div class="mr-2" style="text-transform: capitalize; color: #FF6634;">
                        {{player.licenses.length}} documents</div>
                    </div>
                  </div>
                </div>

                <div class="web-show" style="align-content: center;">
                  <div class="d-flex align-items-center" style="justify-content: flex-end;">
                    <div class="d-flex" style="cursor: pointer; grid-gap: 2rem;">
                      <img src="images/icons/edit.png" (click)="editIndividualMember(i)" click style="width: 24px; height: 28px;">
                      <img src="images/icons/remove_circle.png" (click)="removeSelectedMember(i)" style="width: 24px; height: 28px;">
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
    </div>
    <!-- members adding save -->
    <div class="row mt-3">
      <div class="col-12">
          <div class="row mt-3 last-mob" style="margin: 0;">
            <div class="col-6 p-0 last-full mb-3">
              <button matRipple (click)="previousStep(); clearFullForm();" class="btn bd-7 btn-outline-primary main-height last-full" style="padding: 1rem 5rem; text-decoration: none; border-radius: 5px; white-space: nowrap; display: flex; align-items: center;">
                Back
              </button>
            </div>
            <div class="col-6 p-0 last-full mb-3" style="display: flex; justify-content: flex-end;">
              <button matRipple [swal]="warningSave" class="btn bd-7 btn-primary main-height last-full" style="padding: 1rem 5rem; text-decoration: none; border-radius: 5px; white-space: nowrap; display: flex; align-items: center;">
                Confirm
              </button>
              <swal #warningSave type="warning" title="Are you sure ?" confirmButtonText="Confirm"
                text="Do you want add these Players to academy ?" [showCancelButton]="true"
                cancelButtonClass="btn btn-outline-dark" (confirm)="saveAllPlayerData()">
              </swal>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <!-- Edit save -->
  <div class="card mt-3" *ngIf="editMode">
    <div class="card-body">
      <div class="row mt-3 last-mob">
        <div class="col-12">
            <div class="row mt-3" style="margin: 0;">
              <div class="col-6 p-0 last-full mb-3">
                <button matRipple (click)="CancelEdit()" class="btn bd-7 btn-outline-primary main-height last-full" style="padding: 1rem 5rem; text-decoration: none; border-radius: 5px; white-space: nowrap; display: flex; align-items: center;">
                  Cancel
                </button>
              </div>
              <div class="col-6 p-0 last-full mb-3" style="display: flex; justify-content: flex-end;">
                <button matRipple (click)="confirmIndividualEdit()" class="btn bd-7 btn-primary main-height last-full" style="padding: 1rem 5rem; text-decoration: none; border-radius: 5px; white-space: nowrap; display: flex; align-items: center;">
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>


  <ng-template class="modal fade" #exampleModalContent let-modal>
    <div class="modal-content">
        <div class="modal-header d-flex justify-content-between col-12" style="padding: 1rem 2rem;">
          <h5 class="modal-title mt-2 bd-7" id="exampleModalLabel"><span>
              Upload file</span>
          </h5>
          <img class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()"
            class="model-close" src="images/icons/cancel.png">
        </div>
        <input type="file" (change)="fileChangeEvent($event)" #myfileid id="myfileid" style="display: none;" />
        <!-- <input type="file" (change)="fileChangeEvent($event)" id="myfileid" style="display: none;" /> -->
        <div class="modal-body col-12">
            <div class="w-100 d-flex justify-content-center pt-5 pb-5" *ngIf="fileSelect"
                (click)="openFileSelect()">
                <img src="images/samples/ic_add_image.png" />
            </div>
            <div class="w-100 d-flex justify-content-center pt-5 pb-5" *ngIf="fileSelectDone"
                (click)="openFileSelect()">
                <img src="images/icons/ic_success_full.png" />
            </div>
            <image-cropper [imageChangedEvent]="imageChangedEvent" *ngIf="afterImageUpload"
                [maintainAspectRatio]="false" [resizeToWidth]="900" [resizeToHeight]="900" [aspectRatio]="1 / 1"
                format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" [output]="'base64'"
                (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()" class="cropper-maxh">
            </image-cropper>
        </div>
        <div class="modal-footer col-12">
            <button matRipple type="button" class="btn btn-dark al-start alert-footer main-height" (click)="openFileSelect()">Choose
                File</button>
            <button matRipple type="button" class="btn btn-success alert-footer main-height"
                (click)="uploadAttachment();">Upload</button>
            <button matRipple type="button" class="btn btn-light alert-footer main-height" data-dismiss="modal"
                (click)="modal.dismiss()">Cancel</button>
        </div>
    </div>
  </ng-template>


</div>
