<div class="d-flex align-items-stretch auth auth-img-bg h-100">
  <div class="row flex-grow p-0 m-0 w-100">
      <div class="col-lg-6 d-flex align-items-center justify-content-center">
          <div class="auth-form-transparent text-left p-3">
              <div class="brand-logo">
                  <img src="images/logo.svg" alt="logo">
              </div>
              <h4>Forgot Password</h4>
              <h6 class="font-weight-light">Enter your email address & we will send you the link to reset
              your password.</h6>
              <div >
                  <form class="pt-3" [formGroup]="resetForm" (ngSubmit)="onReset()">
                      <div class="form-group">
                          <label for="exampleInputEmail">Email</label>
                          <div class="input-group">
                              <div class="input-group-prepend bg-transparent">
                                  <span class="input-group-text bg-transparent border-right-0">
                                      <i class="bi bi-person text-primary"></i>
                                  </span>
                              </div>
                              <input type="text" class="form-control form-control-lg border-left-0" id="email" formControlName="email"
                                  placeholder="email">
                          </div>
                          <div class="input-group pt-2" *ngIf="resetForm.get('email').errors?.required  && submmited">
                              <small class="text-danger2">*Email is required.</small>
                          </div>
                          <div class="input-group pt-2" *ngIf="resetForm.get('email').errors?.email  && submmited">
                              <small class="text-danger2">*Enter a valid email.</small>
                          </div>
                          <div class="input-group pt-2" *ngIf="notRegistered  && submmited">
                              <small class="text-danger2">*This email is not registered with Dartle.</small>
                          </div>
                      </div>
                      <div class="my-3">
                          <button class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn webkit-btn" type="submit"
                              >Email the Recovery Link</button>
                      </div>
                  </form>
              </div>
          </div>
      </div>
      <div class="col-lg-6 forgot-half-bg d-flex flex-row">
      </div>
  </div>
</div>
