/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { PasswordStrengthComponent } from "../password-strength/password-strength.component";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PASSWORD_MIN_LENGTH, STATUS_ACTIVE } from '../../../data-models/user-login';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthenticationService } from '../../../services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { SUCCESS } from '../../../data-models/user-data';
import { CommonModule } from '@angular/common';
import { environment } from '../../../../environments/environments';
import { SharedModule } from '../../shared/shared.moudle';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    PasswordStrengthComponent,
    CommonModule,
    SharedModule,
    ReactiveFormsModule
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {
  show_side_panel = false
  loginForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required, Validators.minLength(PASSWORD_MIN_LENGTH)]),
    saveme: new FormControl(true, [Validators.required])
  })
  show = false;
  submmited = false;
  hide = true;
  not_academy = false
  is_parents_or_child = false
  error_text = 'Password Incorrect!'
  error = false
  isLoggedin?: boolean = undefined;
  profile = ""
  pwa_url: string = environment.pwa_url
  isMultiple = false;
  academy_list: any = [];
  user_info: any = {};

  constructor(
    private auth: AuthenticationService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required, Validators.minLength(8)]),
      saveme: new FormControl(true, [Validators.required])
    })
  }

  onImgError(event: any) {
    event.target.src = 'images/samples/ic_profile.png'
  }

  toggle() {
    this.not_academy = false
  }

  /**
   * Function to toggle the password.
   */
  togglePassword() {
    this.hide = !this.hide;
  }

  /**
   * On login Click.
   */
  onLogin() {
    this.submmited = true
    // console.log(this.loginForm.get('email')?.value)
    // console.log(this.loginForm.get('password')?.value)
    if (this.loginForm.get('email')?.value && this.loginForm.get('password')?.value)
      this.login(this.loginForm.get('email')?.value.toLowerCase(), this.loginForm.get('password')?.value)
    else {
      console.log("invalid form")
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  login(email: string, password: string,keep_session?: boolean, delegate?: any) {
    this.auth.checkEmail(email).subscribe({
      next:(result: any)=>{
        if (result.active_status === STATUS_ACTIVE) {
          var is_academy = result.profile_type.filter((item: any) => {
            return item.toLowerCase().includes(("Academy").toLowerCase());
          }).length > 0 || result.profile_type.filter((item: any) => {
            return item.toLowerCase().includes(("Coach").toLowerCase());
          }).length > 0 || result.profile_type.filter((item: any) => {
            return item.toLowerCase().includes(("Admin").toLowerCase());
          }).length > 0

          if (is_academy) {
            if (result.profile_type.filter((item: any) => {
              return item.toLowerCase().includes(("Academy").toLowerCase());
            }).length > 0) {
              // this.auth.login(email, password, result, keep_session)
              this.auth.attemptLogin(email, password, keep_session)
            } else {
              this.auth.getUserDelegates(result._id).subscribe((res: any) => {
                console.log(res)
                if (res.response == SUCCESS) {
                  if (res.data == null || res.data.length == 0) {
                    this.toastr.warning(`Looks like you have not been assigned a role yet. Please contact your Academy Manager to assign a role.`, `Role not assigned.`)
                  } else if (res.data.length == 1) {
                    // this.auth.login(email, password, result, keep_session, res.data[0])
                    this.auth.attemptLogin(email, password, keep_session, res.data[0])
                  } else {
                    this.isMultiple = true
                    this.academy_list = res.data || []
                    this.user_info = {
                      email: email,
                      password: password,
                      result: result,
                      keep_session: keep_session,
                    }
                    // console.log(this.academy_list)
                    this.toastr.warning(`Looks like you have been assigned a role in multiple academies, please select an Academy to continue login.`, `Multiple Academy found.`)
                  }
                }
              })
            }
          } else {
            this.profile = result.profile_type[0].toString()
            this.non_academy_user()
          }
        } else if (result['error'] === "no results found") {
          this.toastr.warning("Your email account is not registered.", 'Wrong Email', {
            progressBar: true,
            closeButton: true
          })
        } else {
          this.toastr.warning("Your Account has been deactivted, contact Dartle support.", 'Warning', {
            progressBar: true,
            closeButton: true
          })
        }
      }
    })
  }

  non_academy_user() {
    this.not_academy = true
    this.toastr.warning("Only Academy can login to web app.", 'Wrong Account Type', {
      progressBar: true,
      closeButton: true
    })
    this.is_parents_or_child = this.profile == "Player"
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  academySelector(delegate: any) {
    this.auth.attemptLogin(this.user_info.email, this.user_info.password, this.user_info.keep_session, delegate)
    // this.auth.login(this.user_info.email, this.user_info.password, this.user_info.result, this.user_info.keep_session, delegate)
  }

}
