import { Routes } from '@angular/router';
import { LoginGuard } from './guards/login.guard';
import { LoginComponent } from './modules/auth/login/login.component';
import { AuthGuard } from './guards/authguard.guard';
import { DashboardComponent } from './modules/dashboard/dashboard/dashboard.component';
import { CalenderComponent } from './modules/calender/calender/calender.component';
import { GalleryComponent } from './modules/gallery/gallery/gallery.component';
import { GalleryDatailsComponent } from './modules/gallery/gallery-datails/gallery-datails.component';
import { SelfRegistrationComponent } from './modules/members/self-registration/self-registration.component';
import { PlaygroundComponent } from './modules/shared/playground/playground.component';
import { ProfileComponent } from './modules/members/profile/profile.component';
import { ForgotPasswordComponent } from './modules/auth/forgot-password/forgot-password.component';
import { MailSendComponent } from './modules/auth/mail-send/mail-send.component';
import { ResetPasswordComponent } from './modules/auth/reset-password/reset-password.component';
import { ConsentComponent } from './modules/auth/consent/consent.component';
import { TermsComponent } from './modules/shared/terms/terms.component';
import { EulaComponent } from './modules/shared/eula/eula.component';
import { PrivacyComponent } from './modules/shared/privacy/privacy.component';


export const routes: Routes = [
  { path: '', pathMatch:'full', redirectTo:'dashboard', data: { title: 'Dashboard' } },
  { path: 'dashboard', component: DashboardComponent,canActivate: [AuthGuard], data: { title: 'Dashboard' } },
  { path: 'terms', component: TermsComponent, canActivate: [AuthGuard], data: { title: 'Terms and Conditions' } },
  { path: 'eula', component: EulaComponent, canActivate: [AuthGuard], data: { title: 'Eula' } },
  { path: 'privacy', component: PrivacyComponent, canActivate: [AuthGuard], data: { title: 'Privacy Policy' } },
  { path: 'playground', component: PlaygroundComponent,canActivate: [AuthGuard], data: { title: 'Playground' } },
  { path: 'calendar', component: CalenderComponent,canActivate: [AuthGuard], data: { title: 'Calendar' } },
  { path: 'login', component: LoginComponent,canActivate: [LoginGuard], data: { title: 'Login' } },
  { path: 'password', component: ForgotPasswordComponent,canActivate: [LoginGuard], data: { title: 'Forgot Password' } },
  { path: 'user/send', component: MailSendComponent,canActivate: [LoginGuard], data: { title: 'Mail Sent!' } },
  { path: 'user/reset/:token/:type', component: ResetPasswordComponent, data: { title: 'Reset Password' } },
  { path: 'user/consent/:token/:type', component: ConsentComponent, data: { title: 'Parental Consent' } },
  { path: 'gallery/:id', component: GalleryComponent, canActivate: [AuthGuard], data: { title: 'Player Gallery' } },
  { path: 'gallery/:id/:type', component: GalleryDatailsComponent, canActivate: [AuthGuard], data: { title: 'Player Gallery' } },
  { path: 'self-registration/:id', component: SelfRegistrationComponent, data: { title: 'Self Registration Portal' } },
  { path: 'profile/:id', component: ProfileComponent,canActivate: [AuthGuard], data: { title: 'Member Profile' } },
  { path: 'members', loadChildren:()=> import('./modules/members/members.module').then(m => m.MembersModule)},
  { path: 'groups', loadChildren:()=> import('./modules/groups/groups.module').then(m => m.GroupsModule)},
  { path: 'recruitment', loadChildren:()=> import('./modules/recruitment/recruitment.module').then(m => m.RecruitmentModule)},
  { path: 'academics', loadChildren:()=> import('./modules/academics/academic.module').then(m => m.AcademicModule)},
  { path: 'sessions', loadChildren:()=> import('./modules/sessions/session.module').then(m => m.SessionsModule)},
  { path: 'homeworks', loadChildren:()=> import('./modules/homeworks/homeworks.module').then(m => m.HomeworksModule)},
  { path: 'personaldevs', loadChildren:()=> import('./modules/personal-dev/personal-dev.module').then(m => m.PersonalDevModule)},
  { path: 'reports', loadChildren:()=> import('./modules/reports/reports.module').then(m => m.ReportsModule)},
  { path: 'assessments', loadChildren:()=> import('./modules/assessments/assessments.module').then(m => m.AssessmentsModule)},
  { path: 'settings', loadChildren:()=> import('./modules/shared/setting/setting.module').then(m => m.SettingsModule)},
];
